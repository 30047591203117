import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const Safety = () => {
  return (
    <Container className="p-5">
      <h1>Safety + Trust</h1>
      <hr />
      <p className="lead">
        It's our top priority that Goodnight Zoom is a safe and comfortable
        experience for everyone involved, especially if children are involved.
        So far, everyone has had only positive experiences on Goodnight Zoom,
        but we realize that this is far from a given as we scale.
      </p>
      <p className="lead">
        These are the steps we're taking to ensure that we remain a trusted
        place for storytime and connection:
      </p>
      <p className="lead">
        <b>1. Screening</b>
        <p>
          If they haven't been personally referred, we setup screening
          interviews with seniors before they interact with children.
        </p>
      </p>
      <p className="lead">
        <b>2. Referrals</b>
        <p>
          Many of seniors are referred to us by people we trust, like our
          partners at nursing homes or assisted living facilities.
        </p>
      </p>
      <p className="lead">
        <b>3. Reporting</b>
        <p>
          Luckily this hasn't happened yet, but we take reports of any
          inappropriate behavior very seriously. We'll investigate any reports
          immediately, and we have a zero-tolerance policy for any unwelcome
          behavior.
        </p>
      </p>
      <br />
      <p class="lead">
        Overall, we know that remote storytime is an unusual idea — but we
        encourage you to try it. Almost every single one of our users has had an
        overwhelmingly positive and warm experience. If you're still unsure,
        reach out to us and we can connect you with someone who's used the
        platform — we're confident they'll have only good things to say.
      </p>
      <p>
        If you have any more questions, feel free to reach out to us at{" "}
        <a href="mailto:info@goodnightzoom.com">info@goodnightzoom.com</a>
      </p>
      <br />
      <div className="text-center">
        <h1 className="mt-4 brand-font">Join Goodnight Zoom</h1>

        <Link to="/register" className="mt-4">
          <Button variant="primary" size="lg">
            Sign Up &rarr;
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Safety;
