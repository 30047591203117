import React from "react";

function Welcome(props) {
  const { currentStep } = props;
  return currentStep !== 0 ? null : (
    <>
      <h2>Welcome!</h2>

      <p className="lead">Once upon a time...</p>
      <p className="lead">... you signed up for Goodnight Zoom.</p>

      <p className="lead">
        The next couple of pages will help us get you set up for storytime, and
        will take about a minute.
      </p>
    </>
  );
}

export default Welcome;
