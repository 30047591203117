import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { getCurrentProfile } from "../../actions/profile";
import logo from "../../assets/gnz_logo.png";
import Button from "react-bootstrap/Button";
import { FaUserEdit, FaHome, FaBookReader, FaBookOpen } from "react-icons/fa";

const Navigation = ({
  auth: { isAuthenticated, loading },
  getCurrentProfile,
  profile: { profile },
  logout
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  var pathname = useLocation().pathname;

  const authLinks = (
    <>
      <ul className="navbar-nav">
        <li className="nav-item">
          {" "}
          <Link to="/home" className="nav-link">
            <FaHome className="mr-2" />
            Home
          </Link>
        </li>
        <li className="nav-item">
          {" "}
          <Link
            className="nav-link"
            to={
              profile === null || profile.approved_reader
                ? "/hosting"
                : "/apply"
            }
          >
            <FaBookReader className="mr-2" />
            Host
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/books">
            <FaBookOpen className="mr-2" />
            Books
          </Link>
        </li>
        <li className="nav-item">
          {" "}
          <Link to="/edit-profile" className="nav-link">
            <FaUserEdit className="mr-2" />
            Profile
          </Link>
        </li>
      </ul>
    </>
  );

  const guestLinks = (
    <>
      <div className="py-2 py-lg-0">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/safety">
              Safety
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">
              How does it work?
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/login">
              Login
            </Link>
          </li>
        </ul>
      </div>
      <Link
        className="btn btn-primary mx-lg-3 text-white brand-font"
        to="/register"
      >
        Sign Up
      </Link>
    </>
  );
  return (
    <div className="navbar-container ">
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container">
          <Link className="navbar-brand fade-page" to="/">
            <img
              src={logo}
              style={{ maxWidth: "13rem" }}
              alt="goodnight zoom logo"
            />
          </Link>

          {/* option to cancel if onboarding and not finished */}
          {profile == null && pathname === "/home" ? (
            <Button variant="light" onClick={logout}>
              Cancel
            </Button>
          ) : (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="injected-svg icon navbar-toggler-open"
                data-src="assets/img/icons/interface/menu.svg"
              >
                <path
                  d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z"
                  fill="#212529"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="injected-svg icon navbar-toggler-close"
                data-src="assets/img/icons/interface/cross.svg"
              >
                <path
                  d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z"
                  fill="#212529"
                ></path>
                <path
                  d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z"
                  fill="#212529"
                ></path>
              </svg>
            </button>
          )}

          <div className="collapse navbar-collapse justify-content-end">
            {!loading && (
              <>
                {isAuthenticated
                  ? profile == null
                    ? ""
                    : authLinks
                  : guestLinks}
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { logout, getCurrentProfile }
)(Navigation);
