import React from "react";
import Form from "react-bootstrap/Form";

function AgeRange(props) {
  const {
    currentStep,
    onCheck,
    kid_age_range,
    adult_age_range,
    senior_age_range,
    onChange,
    stepVal,
    demo
  } = props;

  const getSelect = demo => {
    if (demo === "parent") {
      return (
        <>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="0-3 years old"
              value="0-3"
              onChange={onCheck}
              className="py-2"
              checked={kid_age_range.includes("0-3") ? true : false}
            />
            <Form.Check
              type="checkbox"
              label="3-5 years old"
              value="3-5"
              className="py-2"
              onChange={onCheck}
              checked={kid_age_range.includes("3-5") ? true : false}
            />
            <Form.Check
              type="checkbox"
              label="5-7 years old"
              value="5-7"
              className="py-2"
              onChange={onCheck}
              checked={kid_age_range.includes("5-7") ? true : false}
            />
            <Form.Check
              type="checkbox"
              label="7+ years old"
              value="7+"
              className="py-2"
              onChange={onCheck}
              checked={kid_age_range.includes("7+") ? true : false}
            />
          </Form.Group>
        </>
      );
    } else if (demo === "adult") {
      return (
        <Form.Control
          as="select"
          size="lg"
          name="adult_age_range"
          value={adult_age_range}
          onChange={onChange}
          style={{ maxWidth: "30rem" }}
        >
          <option value="I am..." disabled>
            I am...
          </option>
          <option value="18-35">18-35 years old</option>
          <option value="36-55">36-55 years old</option>
          <option value="55+">55+</option>
          <option value="prefer not">Prefer not to say</option>
        </Form.Control>
      );
    } else if (demo === "senior") {
      return (
        <Form.Control
          as="select"
          size="lg"
          name="senior_age_range"
          value={senior_age_range}
          onChange={onChange}
          style={{ maxWidth: "30rem" }}
        >
          <option value="I am..." disabled>
            I am...
          </option>
          <option value="60-70">60-70 years young</option>
          <option value="70-80">70-80 years young</option>
          <option value="80+">80+ years young</option>
          <option value="prefer not">Prefer not to say</option>
        </Form.Control>
      );
    } else {
      return "ERROR";
    }
  };

  const getQuestion = demo => {
    if (demo === "parent") {
      return (
        <>
          <h2>
            {" "}
            <span role="img" aria-label="birthday">
              {" "}
              🎂{" "}
            </span>{" "}
            How old is your child/children?
          </h2>
          <p>
            This will help us figure out the right reading level for storytime.
            If you have multiple kids, check all the age ranges that apply.
          </p>
        </>
      );
    } else if (demo === "adult") {
      return (
        <>
          <h2>
            {" "}
            <span role="img" aria-label="birthday">
              {" "}
              🎂{" "}
            </span>{" "}
            What's your age range?
          </h2>
          <p>This will help us match you for storytime.</p>
        </>
      );
    } else if (demo === "senior") {
      return (
        <>
          <h2>
            {" "}
            <span role="img" aria-label="birthday">
              {" "}
              🎂{" "}
            </span>{" "}
            What's your age range?
          </h2>
          <p>This will help us match you for storytime.</p>
        </>
      );
    }
  };
  return currentStep !== stepVal ? null : (
    <>
      {getQuestion(demo)}
      <Form.Group>{getSelect(demo)}</Form.Group>
    </>
  );
}

export default AgeRange;
