import React from "react";
import Form from "react-bootstrap/Form";

import Image from "react-bootstrap/Image";
import storyDefault from "../../assets/story_images/story_default.png";

function StoryImage({
  currentStep,
  onImageChange,
  uploadedStoryImage,
  stepVal
}) {
  return currentStep !== stepVal ? null : (
    <>
      <h2>
        <span role="img" aria-label="anything_else">
          {" "}
          📕{" "}
        </span>{" "}
        A photo of your stories
      </h2>

      <p className="lead">
        Please upload a photo that depicts the kind of stories you will tell.
        This can be a photo of book cover, an illustration, etc -- it's up to
        you!
      </p>

      <Form.Group controlId="story-image" className="mb-4 text-center">
        <Image
          src={storyDefault}
          ref={uploadedStoryImage}
          className="my-3"
          rounded
          style={{
            height: "10rem",
            maxWidth: "30rem",
            objectFit: "cover"
          }}
        />
        <br />
        <input
          type="file"
          accept="image/*"
          onChange={e => onImageChange(e, "story")}
          id="image-upload"
          className="btn btn-light mt-3"
        ></input>
      </Form.Group>
    </>
  );
}

export default StoryImage;
