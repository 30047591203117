import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import "toasted-notes/src/styles.css";
import { setAlert } from "../../actions/alert";
import {
  cancelStorytime,
  deleteStorytime,
  getStorytimeId,
  rsvpStorytime
} from "../../actions/storytime";
import StorytimeCard from "./StorytimeCard";

export const StorytimePage = ({
  match,
  storytime,
  profile: { profile, loading },
  getStorytimeId,
  rsvpStorytime,
  cancelStorytime,
  deleteStorytime
}) => {
  useEffect(() => {
    const storytime_id = match.params.storytime_id;
    getStorytimeId(storytime_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getStorytimeId]);

  console.log(storytime);

  return (
    <>
      {loading || storytime.single_storytime === null ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <StorytimeCard
          id={storytime.single_storytime._id}
          storytime={storytime.single_storytime}
          profile={profile}
          rsvpStorytime={rsvpStorytime}
          cancelStorytime={cancelStorytime}
          deleteStorytime={deleteStorytime}
        />
      )}
    </>
  );
};

StorytimePage.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  rsvpStorytime: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  storytime: state.storytime,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { setAlert, rsvpStorytime, cancelStorytime, deleteStorytime, getStorytimeId }
)(StorytimePage);
