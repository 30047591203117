import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentProfile } from "../../actions/profile";
import { newStorytime } from "../../actions/storytime";
import AgeRange from "../hosting/AgeRange";
import Description from "../hosting/Description";
import ProfilePicture from "../hosting/ProfilePicture";
import Schedule from "../hosting/Schedule";
import StoryPicture from "../hosting/StoryPicture";
import Welcome from "../hosting/Welcome";

const Hosting = ({
  profile: { profile, loading },
  getCurrentProfile,
  newStorytime,
  history
}) => {
  const [formData, setFormData] = useState({
    description: "",
    age_range: "4-7 years old",
    event_date: "",
    browser_timezone: ""
  });

  //   Utility function for rounding minutes
  const roundMinutes = date => {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

    return date.setDate(date.getDate() + 1);
  };

  //   Load in current profile
  useEffect(() => {
    getCurrentProfile();
    setFormData({
      description: loading || !profile.bio ? "" : profile.bio,
      event_date: roundMinutes(new Date()),
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getCurrentProfile]);

  // Unpack constants
  const { description, age_range, event_date, browser_timezone } = formData;

  // what step are we on? are there errors present?
  const [step, setStep] = useState(0);
  const [error, toggleError] = useState(false);

  //   State of image uploads
  const [file, setFile] = useState("");
  const [storyFile, setStoryFile] = useState("");

  const uploadedProfileImage = useRef(null);
  const uploadedStoryImage = useRef(null);

  const onImageChange = (e, image_type) => {
    if (image_type === "profile") {
      setFile(e.target.files[0]);
      handleImageUpload(e, uploadedProfileImage);
    } else if (image_type === "story") {
      setStoryFile(e.target.files[0]);
      handleImageUpload(e, uploadedStoryImage);
    }
  };

  const handleImageUpload = (e, image) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = image;
      current.file = file;

      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDateChange = date => {
    setFormData({
      ...formData,
      event_date: date
    });
  };

  const onChange = e => {
    toggleError(false);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", file);
    formData.append("image", storyFile);
    formData.append("description", description);
    formData.append("age_range", age_range);
    formData.append("event_date", moment.tz(event_date, "UTC").format());
    formData.append("browser_timezone", browser_timezone);
    newStorytime(formData, history);
  };

  const showError = e => {
    toggleError(true);
  };

  const prevStep = e => {
    setStep(step - 1);
    toggleError(false);
  };

  const nextStep = e => {
    setStep(step + 1);
    toggleError(false);
  };

  const [show, toggleShow] = useState(true);

  const getBar = () => {
    if (step === 0) {
      return (
        <Button onClick={e => nextStep()} className="hover-arrow">
          Next
        </Button>
      );
      // TODO: TEST TO MAKE SURE YOU CAN"T GO TO NEXT STEP WITH NO PROFILE PICTURE! TRY ON NEW ACCOUNT
    } else if (step === 5) {
      return null;
    } else if (
      (profile.profile_photo === null && step === 1) ||
      (uploadedStoryImage.current === null && step === 2)
    ) {
      return (
        <>
          <Button variant="light" onClick={e => prevStep()}>
            Back
          </Button>
          <Button className="disabled hover-arrow" onClick={e => showError(e)}>
            Next
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button variant="light" onClick={e => prevStep()}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={e => nextStep()}
            className="hover-arrow"
          >
            Next
          </Button>
        </>
      );
    }
  };

  return (
    <Container style={{ maxWidth: "50rem" }} className="mt-4">
      <br />
      <Spinner
        animation="border"
        role="status"
        style={{ margin: "auto" }}
        className={show ? "d-none" : "d-block"}
        variant="primary"
        size="lg"
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
      <Form
        onSubmit={e => onSubmit(e)}
        style={{ height: "30%" }}
        className={show ? "d-block" : "d-none"}
      >
        <Welcome stepVal={0} currentStep={step} />
        <ProfilePicture
          stepVal={1}
          currentStep={step}
          profile={profile}
          onImageChange={onImageChange}
          uploadedProfileImage={uploadedProfileImage}
        />

        <StoryPicture
          stepVal={2}
          currentStep={step}
          profile={profile}
          onImageChange={onImageChange}
          uploadedStoryImage={uploadedStoryImage}
        />

        <Schedule
          stepVal={3}
          currentStep={step}
          event_date={event_date}
          handleDateChange={handleDateChange}
        />

        <AgeRange
          stepVal={4}
          currentStep={step}
          age_range={age_range}
          onChange={e => onChange(e)}
        />
        <Description
          stepVal={5}
          showError={showError}
          currentStep={step}
          description={description}
          onChange={e => onChange(e)}
        />

        {error ? (
          <Alert variant="info">
            Please select an answer above.
            <br />
            <br />
            If you need any help, click the chat button in the lower right hand
            corner and we'll be happy to walk you through this.
          </Alert>
        ) : null}

        <div className="text-center text-md-right">
          <ProgressBar now={step * 20} className="mt-4" />

          <p className="text-muted mt-2">Step {step + 1} of 6</p>
          <ButtonGroup className="mt-3 w-100" size="lg">
            {getBar()}
          </ButtonGroup>
        </div>

        <div className="text-center my-4">
          <p>
            If you need any help, click the chat button in the lower right hand
            corner.
          </p>
        </div>
      </Form>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, newStorytime }
)(withRouter(Hosting));
