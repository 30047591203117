import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="py-4 bg-dark text-white-50"
      style={{
        flexShrink: "none",
        position: "absolute",
        bottom: "0",
        width: "100%"
      }}
    >
      <Container>
        <div className="text-center">
          <a
            href="mailto:saskia@goodnightzoom.com"
            className="text-white brand-font"
          >
            <span role="img" aria-label="email">
              📧
            </span>{" "}
            Contact
          </a>
          <br />
          <Link to="/privacy" className="text-white">
            Privacy Policy
          </Link>
          <br />
          <Link to="/terms" className="text-white">
            Terms of Service
          </Link>
          <p>© 2020 GoodnightZoom </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
