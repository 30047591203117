import React from "react";
import Container from "react-bootstrap/Container";

const Terms = () => {
  return (
    <Container className="my-5">
      <p className="p1">
        <span className="s1">
          <strong>Terms of Service &amp; DMCA Goodnight Zoom</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Hi and welcome! You are now reading Goodnight Zoom’s Terms of Service,
          which include an agreement between you and Goodnight Zoom. in Section
          15 to arbitrate in certain cases instead of going to court.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          This is the legal contract between you and Goodnight Zoom. when you
          use the Goodnight Zoom site, and related services and products. You
          should read this carefully before you use our site, our services or
          products.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          We’ve tried to be both fair and clear—if you have any suggestions for
          improvement, feel free to email us at goodnightzoom@gmail.com. We’ve
          also included annotations throughout this contract in quotes; these
          annotations aren’t a part of the contract itself, but are intended to
          help you follow the text and to emphasize key sections.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>1. Accepting the Terms of Service</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We are Goodnight Zoom,
            <span className="Apple-converted-space">&nbsp;&nbsp;</span>and
            you’re agreeing to these terms.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          These are some of the definitions we’ll use in this contract:
        </span>
      </p>
      <ul className="ul1">
        <li className="li4">
          <span className="s3">
            <strong>Agreement:</strong> These Goodnight Zoom Terms of Service,
            along with our Privacy Policy.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li4">
          <span className="s3">
            <strong>Services:</strong> Goodnight Zoom.com, and/or the other
            domains, products, services, and/or content provided by Goodnight
            Zoom,<span className="Apple-converted-space">&nbsp;&nbsp;</span>(and
            its licensors) as part of Goodnight Zoom, but not the domains,
            products, services or content provided by Goodnight Zoom,
            <span className="Apple-converted-space">&nbsp;&nbsp;</span>as part
            of its services other than Goodnight Zoom.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li4">
          <span className="s3">
            <strong>Goodnight Zoom, :</strong> Also known as “we” or “us”.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li4">
          <span className="s3">
            <strong>Goodnight Zoom:</strong> Goodnight Zoom, ’s services related
            to describing projects and those involved in projects.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li4">
          <span className="s3">
            <strong>User:</strong> That is you, who we’ll also call “you”.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
      </ul>
      <p className="p2">
        <span className="s1">
          By creating an account to use the Services (“Account”) or using or
          accessing the Services, you agree to become bound by all the terms and
          conditions of this Agreement. Please note that your account to use the
          Services will be different from your account to use other Goodnight
          Zoom,<span className="Apple-converted-space">&nbsp;&nbsp;</span>
          services. If you do not agree to all the terms and conditions of this
          Agreement, you shouldn’t and aren’t permitted to use the Services.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>2. Modifications to this Agreement</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We can change this agreement if needed, and when we do, we’ll let
            you know.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may make
          modifications, deletions and/or additions to this Agreement
          (“Changes”) at any time. A Change will be effective: (i) thirty (30)
          days after Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>provides
          notice of such Change, whether such notice is provided through the
          Services user interface, is sent to the e-mail address associated with
          your Account or otherwise; or (ii) when you opt-in or otherwise
          expressly agree to such Change or a version of this Agreement
          incorporating such Change, whichever comes first.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>3. Use of the Services</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>Eligibility: “You have to be thirteen or older.”</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          No individual under the age of thirteen (13) may use the Services or
          provide any information to Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>or
          otherwise through the Services (including, for example, a name,
          address, telephone number, or email address). If you are a parent and
          believe your child under the age of thirteen (13) has created an
          Account or otherwise provided personal information to Goodnight Zoom,
          , please contact us at goodnightzoom@gmail.com. In addition, you may
          only use the Services to the extent not legally prohibited from doing
          so.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Service Changes and Limitations: “We are going to be changing and
            updating the service regularly.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          The Services may change frequently, and their form and functionality
          may change without prior notice to you. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>retains the
          right to create limits on or in relation to use of the Services in its
          sole discretion at any time with or without notice. We will work to
          notify you of any such limits, or of changes to these limits, whenever
          possible.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may also
          impose limits on certain Services or aspects of those Services or
          restrict your access to parts or all of the Services without notice or
          liability. Generally, this will only happen when needed to maintain
          the service or to make necessary updates to the service. Goodnight
          Zoom,<span className="Apple-converted-space">&nbsp;&nbsp;</span>may
          change, suspend, or discontinue any or all of the Services at any
          time, including the availability of any product, feature, database, or
          Content (as defined below). Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may also
          suspend Accounts at any time, in its sole discretion.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Limitations on Automated Use: “You shouldn’t use bots or access the
            system in malicious or un-permitted ways.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          You may not do any of the following while accessing or using the
          Services: (a) access, tamper with, or use non-public areas of the
          Services, or the computer or delivery systems of Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>and/or its
          service providers; (b) probe, scan, or test any system or network
          (particularly for vulnerabilities), or otherwise attempt to breach or
          circumvent any security or authentication measures; (c) access or
          search or attempt to access or search the Services by any means
          (automated or otherwise) other than through our currently available,
          published interfaces that are provided by Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>(and only
          pursuant to those terms and conditions), unless you have been
          specifically allowed to do so in a separate agreement with Goodnight
          Zoom, , or unless specifically permitted by Goodnight Zoom, ’s
          robots.txt file or other robot exclusion mechanisms; (d) scrape the
          Services, or scrape Content (as defined below) from the Services; (e)
          use the Services to send altered, deceptive, or false
          source-identifying information, including without limitation by
          forging TCP-IP packet headers or e-mail headers; or (f) interfere
          with, or disrupt or attempt to interfere with or disrupt, the access
          of any User, host or network, including, without limitation, by
          sending a virus to, spamming, or overloading the Services, or by
          scripted use of the Services in such a manner as to interfere with or
          create an undue burden on the Services.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          If you discover a security breach related to the Services, you shall
          notify us promptly.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>4. Privacy</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>“We’ll follow our privacy policy.”</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Any information you provide to Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>is subject
          to Goodnight Zoom, ’s Privacy Policy, which governs our collection and
          use of your information. You understand that through your use of the
          Services you consent to the collection and use (as set forth in the
          Privacy Policy) of this information.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>5. Content and Intellectual Property Rights</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Definitions: “Content means text, pictures and other stuff; User
            Content is your stuff.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          For purposes of this Agreement: (1) the term “Content” means any work
          of authorship and includes, without limitation, video, audio,
          photographs, images, illustrations, animations, logos, tools, written
          posts, replies, and comments, information, data, text, software,
          scripts, executable files, graphics, and interactive features; (2) the
          term “User Content” is all Content that users of the Services
          (“Users”) create on, or upload to, their Accounts, such as code, text,
          images and other assets.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Ownership: “You own your stuff, and we own our stuff.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          As between you and Goodnight Zoom, , you retain ownership of all
          intellectual property rights in your User Content, and Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>and/or its
          licensors retain ownership of all intellectual property rights in the
          Services and all Content made available through the Services other
          than your User Content. You agree not to sell, license, distribute,
          copy, modify, publicly perform or display, transmit, publish, edit,
          adapt, create derivative works from, or otherwise make unauthorized
          use of the Services, but you may peform these actions on your User
          Content in compliance with the terms of this Agreement.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Content License from You: “You allow us to use your content while
            using and running our services and operating our business.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          As a User of the Services, you hereby grant to Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>a
          worldwide, non-exclusive, royalty-free, full-paid, irrevocable,
          perpetual, transferable, sublicensable right and license to download,
          copy, store, view, display, perform, and analyze the User Content for
          (1) purposes of operating and providing the Services and (2) Goodnight
          Zoom, ’s internal business purposes, including without limitation for
          analyzing usage of and improving our Services.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            User Content: “You have the right to let us use your content. We
            don’t warrant other user’s content.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          You represent and warrant that you either own your User Content or
          have all necessary rights, licenses and consents relating thereto in
          order to grant Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>the license
          rights granted herein without infringement or violation of the rights
          of any third party. You agree that your User Content will not: (i)
          include material that is not authorized by the copyright owner,
          protected by trade secret or otherwise subject to third party
          proprietary rights, including privacy and publicity rights, unless you
          are the owner of such rights or have permission from their rightful
          owner to post and sell the material and to grant Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>all of the
          license rights granted herein; (ii) include falsehoods or
          misrepresentations that could damage Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>or any
          third party; (iii) include material that is unlawful, defamatory,
          libelous, threatening, pornographic, harassing, hateful, racially or
          ethnically offensive or encourages conduct that would be considered a
          criminal offense, give rise to civil liability, violate any law or is
          otherwise inappropriate. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>does not
          endorse any User Content or any opinion, recommendation, or advice
          expressed therein, and Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>expressly
          disclaims any and all liability in connection with User Content. If
          notified by a user or a content owner of any User Content that
          allegedly does not conform to this Agreement, Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may
          investigate the allegation and determine in good faith and in its sole
          discretion whether to remove the User Content from the Services, which
          it reserves the right to do at any time. Additionally, Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may, at any
          time, remove from the Services any User Content that in the sole
          judgment of Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>violates
          this Agreement.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Copyright Infringement; DMCA Policy: “We’ll remove copyrighted
            materials of yours; just let us know about it.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          If you believe that any materials available through our Services
          infringe your copyright, you may request that such materials be
          removed. This request must bear a signature (or electronic equivalent)
          of the copyright holder or an authorized representative and must
          include the following information: (1) identification of the
          copyrighted work that you believe to be infringed, including a
          description of the work and, where possible, a copy or the location of
          an authorized version of the work; (2) identification of the material
          that you believe to be infringing and its location, including a
          description of the material, its location on our Services or other
          pertinent information that will help us to locate the material; (3)
          your name, address, telephone number and email address; (4) a
          statement that you have a good faith belief that the complained of use
          of the materials is not authorized by the copyright owner, its agent
          or the law; (5) a statement that the information in your claim is
          accurate; and (6) a statement that “under penalty of perjury,” you
          declare that you are the lawful copyright owner or are authorized to
          act on the owner’s behalf. Our agent for copyright issues relating to
          our Services is: goodnightzoom@gmail.com. In an effort to protect the
          rights of copyright owners, we reserve the right to suspend your
          Account, delete or disable content alleged to be infringing and/or
          terminate the Account of a repeat infringer.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            Suggestions: “We welcome your suggestions; if you give us any
            suggestion, we have the right to use it.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          We welcome your suggestions for improvements to our Services. If you
          send us any feedback, ideas or other suggestions (“Suggestions”), you
          agree that: (1) you hereby grant us a non-exclusive, perpetual,
          irrevocable, royalty free license to copy, distribute, adapt and use
          your Suggestion(s); (2) none of your Suggestion(s) contain
          confidential or proprietary information of any third party; (3) we may
          use or redistribute Suggestion(s) for any purpose and in any way; (4)
          there is no obligation for us to review your Suggestion(s); and (5) we
          have no obligation to keep any Suggestions confidential.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>6. Use of Trademarks</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “You must get our permission to use our Goodnight Zoom,
            <span className="Apple-converted-space">&nbsp;&nbsp;</span>branding
            and trademarks.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Any use of Goodnight Zoom, ’s trademarks, branding, logos or any other
          such assets requires the express written permission of Goodnight Zoom,
          . If you need to use these assets, contact us at
          goodnightzoom@gmail.com. Any such use will be subject to compliance
          with our trademark guidelines.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>
            7. Warranty Disclaimer; Services Available on an “AS-IS” Basis
          </strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We want Goodnight Zoom to be great for you, but it’s not
            warrantied.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          YOUR ACCESS TO AND USE OF THE SERVICES OR ANY CONTENT IS AT YOUR OWN
          RISK. THE SERVICES AND ANY ANALYTICS PROVIDED THROUGH THE SERVICES ARE
          PROVIDED FOR INFORMATION PURPOSES ONLY AND Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>IS NOT
          RESPONSIBLE OR LIABLE FOR ANY ACTIONS OR OMISSIONS BASED ON SUCH
          ANALYTICS. YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO
          YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE
          FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>DISCLAIMS
          ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>makes no
          representations or warranties of any kind with respect to the
          Services, including any representation or warranty that the use of the
          Services will (a) be timely, uninterrupted or error-free or operate in
          combination with any other hardware, software, system, or data, (b)
          meet your requirements or expectations, (c) be free from errors or
          that defects will be corrected, or (d) be free of viruses or other
          harmful components. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>also makes
          no representations or warranties of any kind with respect to Content;
          User Content is provided by and is solely the responsibility of the
          respective User providing that Content. No advice or information,
          whether oral or written, obtained from Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>or through
          the Services will create any warranty not expressly made herein.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>8. Release From Liability</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We’re not liable for certain issues that could arise from using
            Goodnight Zoom.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          You release, to the fullest extent permitted by law, Goodnight Zoom, ,
          its directors, officers, members, employees, representatives,
          consultants, agents, suppliers, and/or distributors from
          responsibility, liability, claims, demands, and/or damages (actual and
          consequential) of every kind and nature, known and unknown, arising
          out of or in any way connected with the following:
        </span>
      </p>
      <ul className="ul1">
        <li className="li4">
          <span className="s3">
            Disputes between or among Users, including without limitation those
            between you and other Users;
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li4">
          <span className="s3">
            Third party sites and services, including without limitation content
            found on such sites and services; and
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li4">
          <span className="s3">
            Claims relating to the unauthorized access to any data
            communications or User Content stored under or relating to your
            Account, including unauthorized use or alteration of such
            communications or your User Content.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
      </ul>
      <p className="p2">
        <span className="s1">
          You hereby waive applicability of California Civil Code ¤1542, and any
          similar statute or principle of common law. California Civil Code
          ¤1542 says: “A general release does not extend to claims which the
          creditor does not know or suspect to exist in his or her favor at the
          time of executing the release, which if known by him or her must have
          materially affected his or her settlement with the debtor.”
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>9. Limitation of Liability</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “As much as the law allows, Goodnight Zoom,
            <span className="Apple-converted-space">&nbsp;&nbsp;</span>and its
            team isn’t liable for certain types of damages. Goodnight Zoom, ’s
            total liability to you is limited, too.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Goodnight Zoom, ,
          ITS DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, REPRESENTATIVES,
          CONSULTANTS, AGENTS, SUPPLIERS, LICENSORS, AND/OR DISTRIBUTORS SHALL
          NOT BE LIABLE FOR: (A) ANY INCIDENTAL, EXEMPLARY, PUNITIVE,
          CONSEQUENTIAL OR OTHER INDIRECT DAMAGES OF ANY KIND WHATSOEVER; (B)
          LOSS OF PROFITS, REVENUE, DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE
          LOSSES; (C) DAMAGES RELATING TO YOUR INABILITY TO ACCESS OR USE THE
          SERVICES; (D) DAMAGES RELATING TO ANY CONDUCT OR CONTENT OF ANY THIRD
          PARTY OR USER USING THE SERVICES, INCLUDING WITHOUT LIMITATION
          DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OR CONTENT; AND/OR (E)
          DAMAGES IN ANY MANNER RELATING TO ANY USER CONTENT. THIS LIMITATION
          APPLIES TO ALL CLAIMS, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR
          ANY OTHER LEGAL THEORY, WHETHER OR NOT Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>HAS BEEN
          INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND FURTHER WHERE A REMEDY
          SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL LIABILITY
          OF Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>AND ITS
          DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, REPRESENTATIVES, CONSULTANTS,
          AGENTS, SUPPLIERS, LICENSORS AND/OR DISTRIBUTORS, FOR ANY CLAIM UNDER
          THIS AGREEMENT, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO
          THE GREATER OF FIFTY DOLLARS (US$50.00) OR THE AMOUNT YOU PAID US TO
          USE THE APPLICABLE SERVICE(S) IN THE TWELVE (12) MONTHS IMMEDIATELY
          PRECEDING THE EVENTS GIVING RISE TO THE CLAIM.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>
            10. Exclusions to Warranties and Limitation of Liability
          </strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We follow the law of your region in regard to warranties and
            liabilities.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Some jurisdictions may not allow the exclusion of certain warranties
          or the exclusion/limitation of liability as set forth in Section 9, so
          the limitations above may not apply to you.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>11. Legal Compliance; Indemnification</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>“You won’t use Goodnight Zoom to do illegal things.”</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          As a condition of your use of the Services, you represent and warrant
          that you will not use the Services for any purpose that is unlawful or
          prohibited by this Agreement. Access to the Services from territories
          where their contents are illegal is strictly prohibited. You agree to
          comply with all rules, laws, and regulations relating in any way to
          your use of the Services including without limitation rules about
          intellectual property rights, the Internet, technology, data, email,
          and privacy.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          You agree to indemnify and hold Goodnight Zoom, , and its directors,
          officers, members, employees, representatives, consultants, agents,
          suppliers, licensors and/or distributors, harmless from and against
          any demands, suits, actions, claims, losses, damages, liabilities,
          judgments, settlements, costs or expenses, including without
          limitation attorneys’ fees, arising out of or relating to your use or
          misuse of the Services, violation of this Agreement or violation of
          the rights of any other person or entity.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>12. Termination</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “If you want to cancel this agreement, just close your Account. We
            can cancel it too.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Either party may terminate this Agreement at any time by notifying the
          other party. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may also
          terminate or suspend your access to or ability to use any and all
          Services immediately, without prior notice or liability, for any
          reason or no reason, including but not limited to if you breach any of
          the terms or conditions of this Agreement. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may
          immediately terminate or suspend Accounts that have been flagged for
          repeat copyright infringement.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Upon termination of your access to or ability to use a Service,
          including but not limited to suspension of your Account on a Service,
          your right to use or access that Service and any Content will
          immediately cease. The following Sections shall survive any
          termination of these Terms of Service: 4, 5, 6, 7, 8, 9, 10, 11, 12,
          13, 14, 15, 16, and 17. Termination of your access to and use of the
          Services shall not relieve you of any obligations arising or accruing
          prior to such termination or limit any liability which you otherwise
          may have to Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>or any
          third party.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>13. Arbitration; Waiver of className Actions</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We’ll arbitrate instead of going to court for many disputes. We’ll
            only resolve disputes on an individual basis.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Any claim where (a) the total amount of the award sought by either you
          or Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>is less
          than $10,000 and (b) that does not involve patents, copyrights,
          trademarks, trade secrets or moral rights, shall be resolved via
          binding non-appearance-based arbitration initiated through the
          American Arbitration Association (“AAA”). The AAA Rules are available
          online at www.adr.org or by calling the AAA at 1-800-778-7879. In any
          such arbitration, the parties and AAA must comply with the following
          rules: (w) the arbitration shall be conducted by telephone, online
          and/or be solely based on written submissions, and the specific manner
          shall be chosen by the party initiating the arbitration; (x) the
          arbitration shall not involve any personal appearance by the parties
          or witnesses unless otherwise mutually agreed by the parties; (y) the
          arbitrator may award injunctive or declaratory relief only in favor of
          the individual party seeking relief and only to the extent necessary
          to provide relief warranted by that party’s individual claim; and (z)
          any judgment on the award rendered by the arbitrator may be entered in
          any court of competent jurisdiction. Notwithstanding anything in this
          Agreement, (1) either party may seek remedies in small claims court of
          competent jurisdiction and (2) either party may seek injunctive or
          other equitable relief in any court of competent jurisdiction.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Any claims brought by you or us must be brought in that party’s
          individual capacity, and not as a plaintiff or className member in any
          purported className or representative proceeding. Neither you nor us
          will participate in a className action or className-wide arbitration
          for any claims covered by these Agreement. You hereby waive any and
          all rights to bring any claims related to these Agreement and/or our
          Privacy Policy as a plaintiff or className member in any purported
          className or representative proceeding. You may bring claims only on
          your own behalf.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          You may opt out of the agreement to arbitrate in the first paragraph
          of this Section 13 (“Agreement to Arbitrate”). If you do so, neither
          you nor we can require the other to participate in an arbitration
          proceeding. To opt out, you must notify us in writing within thirty
          (30) days of the date that you first became subject to this
          arbitration provision. The opt-out notice must state that you do not
          agree to the Agreement to Arbitrate and must include your name,
          address, phone number, your Account to which the opt-out applies and a
          clear statement that you want to opt out of this Agreement to
          Arbitrate. You must sign the opt-out notice for it to be effective.
          This procedure is the only way you can opt out of the Agreement to
          Arbitrate. You must use email this address to opt out:
        </span>
      </p>
      <p className="p4">
        <span className="s3">goodnightzoom@gmail.com</span>
      </p>
      <p className="p2">
        <span className="s1">
          Notwithstanding any provision in this Agreement to the contrary, you
          and we agree that if we make any change to the arbitration procedures
          (other than a change to any notice address or Site link provided
          herein) in the future, that change shall not apply to any claim that
          was filed in a legal proceeding against us prior to the effective date
          of the change. Moreover, if we seek to terminate the arbitration
          procedures from this Agreement, such termination shall not be
          effective until thirty (30) days after the version of this Agreement
          not containing the arbitration procedures is posted to our Services,
          and shall not be effective as to any claim that was filed in a legal
          proceeding against us prior to the effective date of removal.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          In accordance with Section 12, this Section will survive the
          termination of your relationship with us.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>14. Jurisdiction</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “This agreement is covered by New York law. If there is a dispute
            between us not subject to arbitration, we’ll handle it in New York.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Before resorting to litigation, we strongly encourage you to contact
          us at goodnightzoom@gmail.com to seek a resolution. This Agreement
          shall be governed in all respects by the laws of the State of New York
          as they apply to agreements entered into and to be performed entirely
          within New York between New York residents, without regard to conflict
          of law provisions. You agree that any claim or dispute you may have
          against Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>that isn’t
          subject to mandatory arbitration under Section 14 must be resolved
          exclusively by a state or federal court located in New York County,
          New York, except as otherwise agreed by the parties. Each party agrees
          to submit to the personal jurisdiction of the courts located within
          New York County, New York for the purpose of litigating all such
          claims or disputes.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>
            15. Special Provisions for users Located Outside of the United
            States
          </strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>
            “We’re in the United States, so we’ll follow U.S. law.”
          </strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>provides
          global products and services and enables a global community for
          individuals. Goodnight Zoom, ’s operations are, however, located in
          the United States, and Goodnight Zoom, ’s policies and procedures are
          based on United States law. As such, the following provisions apply
          specifically to users located outside of the United States: (1) you
          consent to the transfer, storage, and processing of your information,
          including but not limited to User Content and any personal
          information, to and in the United States and/or other countries; and
          (2) if you are using the Services from a country embargoed by the
          United States, or are on the United States Treasury Department’s list
          of “Specially Designated Nationals,” you agree that you will not use
          the Services.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>16. Miscellaneous</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>“This is the whole agreement between us.”</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          This Agreement, including as it may be modified from time to time as
          set forth in Section 2 above, constitutes the entire agreement between
          you and Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>with
          respect to the subject matter hereof. This Agreement replaces all
          prior or contemporaneous understandings or agreements, written or
          oral, regarding the subject matter hereof and constitutes the entire
          and exclusive agreement between the parties. The failure of either
          party to exercise in any respect any right provided for herein shall
          not be deemed a waiver of any further rights hereunder. If any
          provision of this Agreement is found to be unenforceable or invalid,
          that provision shall be limited or eliminated to the minimum extent
          necessary so that this Agreement shall otherwise remain in full force
          and effect and enforceable. This Agreement is not assignable,
          transferable, or sublicensable by you except with Goodnight Zoom, ’s
          prior written consent. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may assign
          this Agreement in whole or in part at any time without your consent.
          No agency, partnership, joint venture, or employment is created as a
          result of this Agreement and you do not have any authority of any kind
          to bind Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>in any
          respect whatsoever.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Any notice to Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>that is
          required or permitted by this Agreement shall be in writing and shall
          be deemed effective upon receipt, when delivered in person by
          nationally recognized overnight courier or mailed by first className,
          registered or certified mail, postage prepaid, to Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>
          goodnightzoom@gmail.com Attn: Legal Department. Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>may provide
          notices to you via e-mail to the e-mail address associated with your
          Account or by mail to your mailing address. The Services are provided
          by Goodnight Zoom, , which may be contacted by e-mail at
          goodnightzoom@gmail.com.
        </span>
      </p>
      <p className="p1">
        <span className="s1">
          <strong>17. Special Notice to California Residents</strong>
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>“The state of California makes us tell you this.”</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          If you are a California resident, we are required to inform you that
          you may reach the Complaint Assistance Unit of the Division of
          Consumer Services of the California Department of Consumer Affairs via
          mail at 1625 North Market Blvd., Suite N112, Sacramento, CA 95834 or
          telephone at (916) 445-1254 or (800) 952-5210. Hearing impaired users
          can reach the Complaint Assistance Unit at TDD (800) 326-2297 or TDD
          (916) 322-1700.
        </span>
      </p>
    </Container>
  );
};

export default Terms;
