import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_STORYTIME,
  STORYTIME_ERROR,
  GET_STORYTIME_ID,
  RSVP_STORYTIME,
  APPLY,
  NEW_STORYTIME,
  DELETE_STORYTIME
} from "./types";

// Get storytimes
export const getStorytime = () => async dispatch => {
  console.log("get storytime");
  try {
    const res = await axios.get("/api/storytime");
    dispatch({
      type: GET_STORYTIME,
      payload: res.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Get Storytime by ID
export const getStorytimeId = storytime_id => async dispatch => {
  console.log("get storytime by id");
  try {
    const res = await axios.get(`/api/storytime/${storytime_id}`);
    dispatch({
      type: GET_STORYTIME_ID,
      payload: res.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Apply to storytime
export const apply = history => async dispatch => {
  try {
    const res = await axios.get("/api/storytime/apply");
    dispatch({
      type: APPLY,
      payload: res.data
    });
    dispatch(setAlert("Thanks! We'll reach out soon.", "success"));
    history.push("/home");
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    history.push("/home");
  }
};

// Create a new storytime
export const newStorytime = (formData, history) => async dispatch => {
  try {
    console.log(formData);
    const res = await axios.post("/api/storytime/new_storytime", formData);

    console.log(res);
    dispatch({
      type: NEW_STORYTIME,
      payload: res.data
    });

    dispatch(setAlert("New Storytime Added", "success"));
    history.push("/home");
  } catch (error) {
    console.log(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Delete Storytimes
export const deleteStorytime = (storytime_id, history) => async dispatch => {
  try {
    await axios.delete(`/api/storytime/${storytime_id}`);
    dispatch({
      type: DELETE_STORYTIME,
      payload: storytime_id
    });
    dispatch(setAlert("No problem, cancelled.", "info"));
    history.push("/home");
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.msg, status: error }
    });
    dispatch(setAlert(error.msg, "danger"));
  }
};

// RSVP to a storytime
export const rsvpStorytime = storytime_id => async dispatch => {
  try {
    const res = await axios.put(`/api/storytime/rsvp/${storytime_id}`);

    dispatch({
      type: RSVP_STORYTIME,
      payload: res.data
    });
    dispatch(
      setAlert("Confirmed! Check your email for more details.", "success")
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const cancelStorytime = storytime_id => async dispatch => {
  try {
    const res = await axios.put(`/api/storytime/cancel/${storytime_id}`);
    dispatch({
      type: RSVP_STORYTIME,
      payload: res.data
    });
    dispatch(setAlert("No problem, cancelled.", "info"));
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORYTIME_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
