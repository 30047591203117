import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Link, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";

const Register = ({ register, isAuthenticated }) => {
  const params = new URLSearchParams(useLocation().search);
  // Template: goodnightzoom.com/register?name=test&email=charlieholtz@gmail.com

  const [formData, setFormData] = useState({
    name: params.get("name") === null ? "" : params.get("name"),
    email: params.get("email") === null ? "" : params.get("email"),
    password: ""
  });

  const { name, email, password } = formData;

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateName(name) {
    return name.length > 0;
  }

  function validatePassword(password) {
    return password.length >= 6;
  }

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    register({ name, email, password });
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <Container fluid className="bg-cta-light pb-5">
      <Row>
        <Container className="mt-5 px-3">
          <Card
            style={{
              maxWidth: "35rem",
              margin: "auto"
            }}
            className="shadow"
          >
            <Card.Body>
              <Card.Title>Sign Up</Card.Title>
              <Form onSubmit={onSubmit}>
                <Form.Group controlId="formEmail">
                  <Form.Label>
                    Email{" "}
                    {validateEmail(email) ? (
                      <span
                        id="check-email"
                        role="img"
                        aria-label="check-email"
                      >
                        ✅
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    size="lg"
                    className={validateEmail(email) ? "border-success" : ""}
                    placeholder="drseuss@gmail.com"
                    value={email}
                    onChange={e => onChange(e)}
                    autoComplete="email"
                  />
                </Form.Group>
                <Form.Group controlId="formName">
                  <Row>
                    <Col>
                      <Form.Label>
                        Full Name{" "}
                        {validateName(name) ? (
                          <span
                            id="check-name"
                            role="img"
                            aria-label="check-name"
                          >
                            ✅
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        spellCheck="false"
                        size="lg"
                        placeholder="Shel Silverstein"
                        value={name}
                        onChange={e => onChange(e)}
                        autoComplete="full-name"
                        className={validateName(name) ? "border-success" : ""}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label>
                    Password{" "}
                    {validatePassword(password) ? (
                      <span
                        id="check-password"
                        role="img"
                        aria-label="check-password"
                      >
                        ✅
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    size="lg"
                    className={
                      validatePassword(password) ? "border-success" : ""
                    }
                    value={password}
                    onChange={e => onChange(e)}
                    placeholder="*******"
                    minLength="6"
                    autoComplete="new-password"
                  />

                  <Form.Text className="text-muted">
                    Make sure your password is at least 6 characters.
                  </Form.Text>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="mt-4"
                  block
                >
                  Join (it's free!)
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <Card style={{ maxWidth: "25rem", margin: "auto" }} className="mt-5">
            <Card.Body>
              <p className="text-muted text-center mb-0">
                Already have an account? <Link to="/login">Log In</Link>
              </p>
            </Card.Body>
          </Card>
        </Container>
      </Row>
      <br />
      <br />
      <br />
    </Container>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { register }
)(Register);
