import React from "react";
import Container from "react-bootstrap/Container";

const Privacy = () => {
  return (
    <Container className="my-5">
      <p>
        <span className="s1">
          <strong>Privacy Policy</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          Goodnight Zoom takes the private nature of your personal information
          very seriously. We respect your privacy and strive to use your
          information in ways that you can understand, predict and control.
        </span>
      </p>
      <p>
        <span className="s1">
          We’ve tried to make this privacy policy readable because we really do
          want you to read it.
        </span>
      </p>
      <p>
        <span className="s1">We’ll use a few terms in this policy:</span>
      </p>
      <ul>
        <li className="li3">
          <span className="s3">
            <strong>“Privacy Policy”:</strong> This policy regarding our privacy
            practices
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li3">
          <span className="s3">
            <strong>“Goodnight Zoom”, “we”, “us”, or “our”:</strong> Goodnight
            Zoom,&nbsp;
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li3">
          <span className="s3">
            <strong>“Site”:</strong> Goodnight Zoom.com
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li3">
          <span className="s3">
            <strong>“Services”:</strong> Goodnight Zoom.com, and/or the other
            domains, products, services, and/or content provided by Goodnight
            Zoom,<span className="Apple-converted-space">&nbsp;&nbsp;</span>
            (and its licensors) as part of Goodnight Zoom, but not the domains,
            products, services or content provided by Goodnight Zoom,
            <span className="Apple-converted-space">&nbsp;&nbsp;</span>as part
            of its services other than Goodnight Zoom.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li3">
          <span className="s3">
            <strong>“Goodnight Zoom”:</strong> Goodnight Zoom, ’s services
            related to describing projects and those involved in projects.
          </span>
          <span className="s1">
            <br />
          </span>
        </li>
      </ul>
      <p>
        <span className="s1">
          This Privacy Policy describes how we treat the information we collect
          when you visit and use any of the Services. When you use any of the
          Services, you are consenting to the collection, transfer,
          manipulation, storage, disclosure and other uses of your information
          as described in this Privacy Policy.
        </span>
      </p>
      <p>
        <span className="s1">
          Because your privacy is important, we ask that you please read this
          Policy carefully. We’ve also included highlighted annotations
          throughout this policy in quotes; these annotations aren’t a part of
          the policy itself, but are intended to help you follow the text and to
          emphasize key sections.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>1. Children</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “We do not collect personally identifiable information from
            children.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          The Services are not directed towards, and Goodnight Zoom,
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>does not
          knowingly collect information from, children under the age of thirteen
          (13). If you are a parent and believe your child under the age of
          thirteen (13) has created a Services account or otherwise provided
          personal information to Goodnight Zoom, , please contact us at
          goodnightzoom@gmail.com.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>2. What This Privacy Policy Covers</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “This Policy covers information you share with Goodnight Zoom, not
            third parties.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          This Privacy Policy covers our treatment of information gathered when
          you are using or accessing the Services. This Privacy Policy does not
          apply to the practices of third parties that we do not own or control,
          including but not limited to any third party websites, services and
          applications (“Third Party Services”) that you elect to access through
          the Service or to individuals that we don’t manage or employ. We
          encourage you to carefully review the privacy policies of all Third
          Party Services you access.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>3. What Information We Collect and How We Use It</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Related to Use of the Services: “We use third party
            statistics and analytics services. Read their policies.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We collect information about how people use the Services, including
          without limitation those with an Account. This information includes
          general usage information, and may include information such as the
          number and frequency of our visitors, which pages or features of the
          Services they have visited, which links on the Services they have
          clicked on, and the length of those visits. We may also use third
          party applications and services, such as Google Analytics, to collect
          and analyze this information. This information enables us and third
          parties authorized by us to figure out how often individuals use the
          Services so that we can analyze and improve them. Some of this
          information may be associated with the IP Address (as defined below)
          used to access the Services, and some may be associated with your
          Account, such as the topics you search for and the help pages that you
          visit. We may also use some of this information in aggregate form,
          that is, as a statistical measure related to all of our users that
          would not identify you personally. We use information about your use
          of the Services to improve and enhance your experience on the
          Services.
        </span>
      </p>
      <p>
        <span className="s1">
          For your convenience, you may view the Google Analytics Terms of
          Service: http://www.google.com/analytics/terms/us.html
        </span>
      </p>
      <p>
        <span className="s1">
          And you can view the Google Analytics Privacy Policy:
          http://www.google.com/analytics/learn/privacy.html
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Related to Your Web Browser: “Web browsers typically
            send some data when you visit us; We use that to make Goodnight Zoom
            better.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We automatically receive and record information from your web browser
          when you interact with the Services, such as your browser type and
          version, what sort of device you are using, your operating system and
          version, your language preference, the website or service that
          referred you to the Services, the date and time of each web request
          you make, your screen display information, and information from any
          cookies we have placed on your web browser (as described below). We
          also sometimes detect whether you are using certain web browser
          extensions and store that information in a manner associated with your
          Account. Web browser-related information is used to enhance your
          experience with the Services (for example, by personalization) and to
          allow us to improve the Services; it is not, however, used in a manner
          that would identify you personally.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Collected Using Cookies: “We use cookies to make
            Goodnight Zoom work for you. If you turn them off, you won’t be able
            to use Goodnight Zoom. Some statistics services we use set cookies,
            too.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          Cookies are pieces of information that may be sent to and saved by
          your web browser when you access a website; your web browser stores
          these cookies in a way associated with each website you visit, and you
          can see your cookies through your browser settings. We use cookies to
          enable our servers to recognize your web browser and tell us how and
          when you use the Services. Our cookies do not, by themselves, contain
          information that personally identifies you, and we don’t combine the
          general information collected through cookies with other information
          to tell us who you are. However, we do use cookies to identify that
          you have logged in and that your web browser has accessed aspects of
          the Services, and we may associate that information with your Account
          if you have one. This information, in turn, is sometimes used to
          personalize your experiences on the Services, such as by presenting
          you with a different feature when you are logged in to Goodnight Zoom.
          Most web browsers have an option for turning off the cookie feature,
          which will prevent your browser from accepting new cookies, as well as
          (depending on your web browser software) allowing you to decide to
          accept each new cookie in a variety of ways. If you disable cookies,
          you won’t be able to log into your Account, and so will not be able to
          use the vast majority of our Services; as such, we don’t recommend
          disabling your cookies when using the Services. Some third-party
          services that we use, such as Google Analytics, may also place their
          own cookies on your browser. Note that this Privacy Policy covers our
          use of cookies only and does not cover the use of cookies by third
          parties.
        </span>
      </p>
      <p>
        <span className="s1">
          We do not currently recognize or respond to browser-initiated Do Not
          Track signals as there is no industry standard for compliance. We do
          not track you across the internet, and we do not change or disable the
          DNT setting in your browser.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Derived Information: “We may deduce information about you from your
            activity. We only do that to provide Goodnight Zoom services to
            you.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We analyze your actions on the Services in order to derive or infer
          characteristics that may be descriptive of you. These characteristics
          are used to improve and personalize the Services.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Email Tracking: “We may sometimes put a tracking link in emails to
            know if you opened an email or clicked on a link in it.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We may place information in our emails to you (such as a web beacon)
          that allows us to measure our email deliverability, and we may track
          which links in an email are followed. We may also use third-party
          email services to deliver messages and you should review the policies
          of these services which govern these messages as well.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>4. Remarketing</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “You may see more advertising for Goodnight Zoom when you browse the
            web.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          In the future, we may use marketing services provided by Google and
          other third parties that allow us to deliver advertising to users
          after they leave our Services that they will see elsewhere on the web.
          This is a common practice called “remarketing.” Users will not see
          more ads than they otherwise would see as a result of remarketing;
          rather, the ads they see are more likely to be ads for Goodnight Zoom
          products and services. Google (and any other third party marketing
          services providers we use to help us with remarketing) use cookies as
          part of the remarketing service. For more information regarding
          remarketing and the ability to opt-out, please visit:
        </span>
      </p>
      <ul>
        <li className="li3">
          <span className="s3">http://www.google.com/ads/preferences/</span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li3">
          <span className="s3">http://www.networkadvertising.org</span>
          <span className="s1">
            <br />
          </span>
        </li>
        <li className="li3">
          <span className="s3">http://www.aboutads.info</span>
          <span className="s1">
            <br />
          </span>
        </li>
      </ul>
      <p>
        <span className="s1">
          <strong>5. With Whom Your Information Is Shared</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “We don’t share your personal information without telling you.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We never share personal information we receive from you unless: (a) we
          have your permission to share that information; (b) we have given you
          prior notice (such as within this privacy policy) that the information
          will be shared, and with whom; or (c) that information is aggregate
          information or other information that does not identify you.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Shared Between the Services: “We might combine your use
            of different networks together to improve Goodnight Zoom.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We may, if possible, aggregate information about your use of multiple
          Services and use that consolidated information to improve how the
          Services operate, and to develop new Services.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Shared with Our Agents in Order to Operate and Improve
            the Services: “If we hire a contractor or consultant, they’ll follow
            the same policy here.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          In some cases, we share information that we store (such as IP
          Addresses) with third parties, such as our service providers,
          consultants and other agents (“Agents”), for the purposes of operating
          and improving the Services. For example, we may share information with
          service providers in order to fight spam, and third-party consultants
          may have access to information in the process of improving our
          processes and technology. Agents with whom we share such information
          for these reasons are generally bound by confidentiality obligations
          and, unless we tell you differently, our Agents do not have any right
          to use information we share with them beyond the scope and duration of
          what is necessary to assist us. You hereby consent to our sharing of
          your information with our Agents.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Shared with Third Parties: “If we share information with
            business partners, it would be aggregate information.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We may share or disclose non-private information, information that is
          aggregated with information relating to other Service user that does
          not personally identify you, or other non-personally identifiable
          Information with people and entities that we do business with.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Disclosed Pursuant to Business Transfers: “If we sell
            Goodnight Zoom or its assets, your information would come along in
            the sale.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          In some cases, we may choose to buy or sell business assets. In these
          transactions, user information is typically one of the transferred
          business assets. Moreover, if we, or substantially all of our assets,
          were acquired, or if we go out of business or enter bankruptcy, user
          information would be one of the assets that is transferred or acquired
          by a third party. You acknowledge that such transfers may occur, and
          that any acquirer of us or our assets may continue to use your
          personal information as set forth in this policy.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information Disclosed for Our Protection and the Protection of
            Others: “We will disclose information if needed to obey the law or
            to protect people.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We believe in freedom of expression, and we will work to protect our
          community from baseless legal demands. That said, we must reserve the
          right to access, preserve, and disclose any information we reasonably
          believe is necessary to (i) satisfy any law, regulation, legal
          process, governmental request, or governmental order, (ii) enforce
          this Privacy Policy and our Terms of Service, including without
          limitation investigation of potential violations hereof, (iii) detect,
          prevent, or otherwise address fraud, security, or technical issues
          (including exchanging information with other companies and
          organizations for fraud protection and spam/malware prevention), (iv)
          respond to user support requests, or (v) protect the rights, property,
          health, or safety of us, our users, any third parties, or the public
          in general, including but not limited to situations involving possible
          violence, suicide, or self-harm.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            Information We Share with Your Consent or at Your Request: “If you
            want to share your data, we’ll accommodate you.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          If you ask us to release information that we have about your Account,
          we will do so if reasonable and not unduly burdensome.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>6. The Security of Your Information</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>“We’re keeping your data reasonably safe.”</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          Your Account information is protected by a password or by connection
          to a Third-Party Service for your privacy and security. You need to
          prevent unauthorized access to your Account and personal information
          by selecting and protecting your password appropriately and limiting
          access to your computer and browser by signing off after you have
          finished accessing your Account on the Services.
        </span>
      </p>
      <p>
        <span className="s1">
          We use reasonable measures to protect your information (including your
          Account information) to ensure that it is kept private; however, we
          can’t guarantee the security of any information. Unauthorized entry or
          use, hardware or software failure, and other factors, may compromise
          the security of user information at any time.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>7. What Information You Can Access</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “You can see your account information in your Settings page.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          If you are a registered user, you can access and delete most
          information associated with your Account by logging into the Services
          and deleting projects or content. Registered and unregistered users
          can access and delete cookies through their web browser settings.
        </span>
      </p>
      <p>
        <span className="s1">
          Your California Privacy Rights: Under California Civil Code sections
          1798.83-1798.84, California residents are entitled to ask us for a
          notice identifying the categories of personal customer information
          that we share with our affiliates and/or third parties for marketing
          purposes, and providing contact information for such affiliates and/or
          third parties. If you are a California resident and would like a copy
          of this notice, please submit a written request to
          goodnightzoom@gmail.com
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            8. How to Delete Your Account and What Happens When You Delete Your
            Account
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “You can email us to close your account, and we’ll delete your
            account. Some information may persist for a while after your account
            is deleted.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          If you want to delete your Account, you can do so from your Membership
          page or by emailing us at goodnightzoom@gmail.com and providing proof
          of authority over the Account. What constitutes “proof of authority”
          will vary depending on the circumstances, but generally will require
          sufficient identifying information so that we can be confident you are
          the Account owner. Deleting your Account does not remove the content
          you have published from our systems and we may continue to use the
          content in accordance with our Terms of Service. In addition, given
          the nature of sharing on the Services, the public activity on your
          Account prior to deletion may remain stored on our servers and
          accessible to the public. Even after you delete your Account, there
          may be records of any contributions you have made to projects created
          by others.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>Changes to This Privacy Policy</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “We may change this privacy policy. If we do, we’ll tell you.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          We may amend this Privacy Policy from time to time, using the process
          for modifications set forth in our Terms of Service. Use of
          information we collect now is subject to the current version of our
          Privacy Policy regardless of when we collected the information.
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>Where to Direct Questions or Concerns</strong>
        </span>
      </p>
      <p>
        <span className="s1">
          <strong>
            “Seriously: If you have any questions or concerns about this policy,
            email us.”
          </strong>
        </span>
      </p>
      <p>
        <span className="s1">
          If you have any questions or concerns regarding privacy using the
          Services, please send us a detailed message to goodnightzoom@gmail.com
        </span>
      </p>
    </Container>
  );
};

export default Privacy;
