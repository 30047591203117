import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "toasted-notes/src/styles.css";
import { setAlert } from "../../actions/alert";
import {
  cancelStorytime,
  deleteStorytime,
  rsvpStorytime
} from "../../actions/storytime";

const Calendar = ({ storytime: { storytime, loading }, profile }) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date_format = "dddd MMM D";

  var enumerateDaysBetweenDates = function(startDate, endDate) {
    var now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format(date_format));
      now.add(1, "days");
    }
    return dates;
  };

  const weekly_range = enumerateDaysBetweenDates(
    moment(),
    moment().add(8, "days")
  );

  function groupBy(arr) {
    const map = new Map(weekly_range.map(day => [day, []]));

    arr.forEach(obj =>
      map
        .get(moment.tz(obj.event_date, browserTimezone).format(date_format))
        .push(obj)
    );
    return Array.from(map).map(([date, story]) => ({ date, story })); // [{key:"A", value: 1}, ... ];
  }

  const grouped = groupBy(storytime).splice(0, 6);

  return (
    <div>
      <>
        {storytime == null || loading ? (
          "loading..."
        ) : (
          <Row key={Date.now()}>
            {grouped.map(group => (
              <Col
                key={group.date}
                sm={12}
                md={2}
                className=" p-2 rounded text-center"
                style={{ border: "3px solid white", background: "#fcf8ff " }}
              >
                <p
                  className="mb-2 text-left text-md-center"
                  style={{ fontSize: "12px" }}
                >
                  <b>{group.date.split(" ")[0]}</b>
                  <br />
                  <span>{group.date.split("y").splice(1, 3)}</span>
                </p>
                {group.story.map(story => (
                  <div key={story._id}>
                    <Link to={`storytime/${story._id}`}>
                      <Button
                        variant="light"
                        className="my-1 hover-shadow"
                        block
                      >
                        <Image
                          src={story.profile_photo}
                          className="avatar avatar-sm"
                        />
                        <br />
                        <span>{story.name.split(" ")[0]}</span>
                        <br />
                        <small className="text-muted">
                          {moment
                            .tz(story.event_date, browserTimezone)
                            .format("h:mm a")}{" "}
                        </small>
                      </Button>
                    </Link>
                    <br />
                  </div>
                ))}
              </Col>
            ))}
          </Row>
        )}
        <hr />

        <Card style={{ border: "none" }} className="text-center">
          <Card.Body>
            {" "}
            <div className="text-center">
              <h5>New storytimes announced every week.</h5>
              <Link
                to={
                  profile === null || profile.approved_reader
                    ? "/hosting"
                    : "/apply"
                }
              >
                {" "}
                <Button>Want to host your own storytime?</Button>
              </Link>
            </div>{" "}
          </Card.Body>
        </Card>
        <br />
        <br />
        <br />
        <br />
      </>
    </div>
  );
};

Calendar.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  storytime: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  storytime: state.storytime
});

export default connect(
  mapStateToProps,
  { setAlert, rsvpStorytime, cancelStorytime, deleteStorytime }
)(Calendar);
