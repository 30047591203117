import React, { Fragment, useEffect } from "react";
// Redux
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { loadUser } from "./actions/auth";
import "./App.css";
import "./Colors.css";
import Admin from "./components/admin/Admin";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Alice from "./components/books/Alice";
import Fairy from "./components/books/Fairy";
import Jungle from "./components/books/Jungle";
import Dashboard from "./components/dashboard/Dashboard";
import About from "./components/landing/About";
import Books from "./components/landing/Books";
import FAQs from "./components/landing/FAQs";
import Footer from "./components/layout/Footer";
import Landing from "./components/landing/Landing";
import Privacy from "./components/landing/Privacy";
import Safety from "./components/landing/Safety";
import Terms from "./components/landing/Terms";
import Alerter from "./components/layout/Alerter";
import Navigation from "./components/layout/Navigation";
import EditProfile from "./components/profile-forms/EditProfile";
import Apply from "./components/profile-forms/Apply";
import PrivateRoute from "./components/routing/PrivateRoute";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import ScrollToTop from "./components/layout/ScrollToTop";
import StorytimePage from "./components/storytimes/StorytimePage";
import NewStorytime from "./components/profile-forms/NewStorytime";
import Hosting from "./components/profile-forms/Hosting";
import Listening from "./components/landing/Listening";

if (localStorage.token) {
  console.log("LOCAL STORAGE");
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div
          className="main-container"
          style={{
            minHeight: "100vh",
            overflow: "hidden",
            display: "block",
            position: "relative",
            paddingBottom: "100px",
          }}
        >
          <ScrollToTop>
            <Fragment>
              <Navigation />
              <Alerter style={{ postion: "absolute", top: "15px" }} />

              <Switch>
                <Route exact path="/" component={Landing} />

                <Route
                  exact
                  path="/register"
                  component={() => {
                    window.location.href =
                      "https://form.typeform.com/to/unF8WJ";
                    return null;
                  }}
                />
                {/*
                <Route exact path="/register" component={Register} /> */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/safety" component={Safety} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/about" component={About} />
                <Route exact path="/jungle" component={Jungle} />
                <Route exact path="/alice" component={Alice} />
                <Route exact path="/fairy" component={Fairy} />
                <Route exact path="/faqs" component={FAQs} />
                <PrivateRoute exact path="/books" component={Books} />
                <PrivateRoute
                  exact
                  path="/storytime/:storytime_id"
                  component={StorytimePage}
                />
                <PrivateRoute exact path="/apply" component={Apply} />
                <PrivateRoute exact path="/home" component={Dashboard} />
                <PrivateRoute exact path="/hosting" component={Hosting} />
                <PrivateRoute exact path="/listening" component={Listening} />
                <PrivateRoute
                  exact
                  path="/edit-profile"
                  component={EditProfile}
                />
                <PrivateRoute
                  exact
                  path="/new-storytime"
                  component={NewStorytime}
                />
                <Route exact path="/admin" component={Admin} />
              </Switch>

              <br />
              <br />
              <br />
              <Footer />
            </Fragment>
          </ScrollToTop>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
