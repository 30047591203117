import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
// import { TwitterTweetEmbed } from "react-twitter-embed";
import landing3 from "../../assets/landing/landing3.png";
import lara from "../../assets/landing/lara.png";
import london from "../../assets/landing/london.png";
import lucy from "../../assets/landing/lucy.png";
import papa from "../../assets/landing/papa.png";
import story_world2 from "../../assets/landing/story_world2.png";
import books from "../../assets/landing/toddler_reading.png";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { saveAnonUser } from "../../actions/auth";
import Form from "react-bootstrap/Form";

const Landing = ({ isAuthenticated, saveAnonUser, history }) => {
  const [email, setEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    saveAnonUser(email, history);
  };

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <Jumbotron className="px-5 bg-cta-light" fluid>
        <Row>
          <Col md={6} xl={5} className="px-4 px-md-5 mt-5">
            <h3 className="text-brand">
              Live video storytime with isolated seniors.
            </h3>
            <p className="lead text-brand mb-4">
              A fun, new experience for your kids and a chance to brighten
              someone's day. Across timezones and generations, find an upcoming
              storytime that works for you and join via a 1-click videochat
              link.
            </p>

            <Link to="/register">
              <Button
                variant="primary"
                size="lg"
                className="hover-arrow"
                type="submit"
                block
              >
                <span className="brand-font">Sign up</span>
              </Button>
            </Link>
          </Col>
          <Col>
            {" "}
            <iframe
              src="https://player.vimeo.com/video/527761839?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="640"
              height="360"
              frameborder="0"
              style={{ width: "100%", maxWidth: "35rem" }}
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="Goodnight Zoom"
            ></iframe>
          </Col>
        </Row>
        <Row className="text-center mt-2">
          <Col>
            {" "}
            <a
              href="https://www.producthunt.com/posts/goodnight-zoom?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-goodnight-zoom"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 mt-1"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=190141&theme=light&period=daily"
                alt="Goodnight Zoom - Storytime between your child and an isolated senior. | Product Hunt Embed"
                style={{ width: "250px", height: "54px" }}
                className="text-center mx-auto"
                width="250px"
                height="54px"
              />
            </a>
            <a href="https://www.producthunt.com/newsletter/4615">
              <Button
                className="bg-white text-primary mt-1"
                size="md"
                style={{ height: "54px", borderRadius: "15px" }}
              >
                <span role="img" aria-label="trophy">
                  🏆
                </span>{" "}
                1st Place, Makerfest (1700+ votes)
              </Button>
            </a>
          </Col>
        </Row>
      </Jumbotron>

      <Container className="text-center mx-0 mt-2 py-5" fluid>
        <Row>
          <Col sm={4}>
            <img
              src={lara}
              alt="..."
              className="rounded-circle"
              style={{ height: "80px", width: "80px", objectFit: "cover" }}
            ></img>
            <blockquote className="blockquote pt-3">
              <p className="mb-0">
                <b>"So beautiful for me and Levi."</b>{" "}
              </p>
              <footer className="blockquote-footer py-1">
                Lara, mother of 3
              </footer>
            </blockquote>
          </Col>
          <Col sm={4}>
            <img
              src={papa}
              alt="..."
              className="rounded-circle"
              style={{ height: "80px", width: "80px", objectFit: "cover" }}
            ></img>
            <blockquote className="blockquote pt-3">
              <p className="mb-0">
                <b>"A wonderful chance to connect with another generation."</b>
              </p>
              <footer className="blockquote-footer py-1">Barrie, 77</footer>
            </blockquote>
          </Col>
          <Col sm={4}>
            <img
              src={lucy}
              alt="..."
              className="rounded-circle"
              style={{ height: "80px", width: "80px", objectFit: "cover" }}
            ></img>
            <blockquote className="blockquote pt-3">
              <p className="mb-0">
                <b> "I like stories."</b>
              </p>
              <footer className="blockquote-footer py-1">Lucy, 6</footer>
            </blockquote>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="p-5 bg-feature1">
          <Col md={7} className="my-5">
            <h1 className="text-feature1-dark">
              Improve your child's reading level.
            </h1>
            <p className="lead">
              Want to increase your child's vocabulary, empathy, and listening
              skills? These are just some of the many{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://journals.sagepub.com/doi/pdf/10.1080/10862969609547932"
              >
                research-backed
              </a>{" "}
              benefits of storytime.
            </p>
          </Col>
          <Col className="my-md-5">
            <img
              src={books}
              style={{
                maxWidth: "100%",
                margin: "auto",
              }}
              className="shadow rounded"
              alt=""
            />
          </Col>
        </Row>
        <Row className="p-5 bg-feature2" fluid>
          <Col className="my-5 text-center">
            <img
              src={story_world2}
              className="rounded shadow"
              style={{
                height: "350px",
                margin: "auto",
                objectFit: "cover",
              }}
              alt=""
            />
          </Col>
          <Col className="my-5 order-first order-md-last" md={6}>
            {" "}
            <h1 className="text-feature2-dark">
              Storytime from diverse voices around the world.
            </h1>
            <p className="lead">
              Listen to storytellers like Maha, a beekeeper who lives in
              Southern India. Connect across generations and borders through
              stories.
            </p>
          </Col>
        </Row>
        <Row className="p-5 bg-feature3" fluid>
          <Col className="my-5" md={6}>
            <h1 className="text-feature3-dark">
              A new experience for your kids.
            </h1>
            <p className="lead">
              Our seniors are more isolated than ever. Let them take your
              child's attention while you take a much deserved break.
            </p>
          </Col>
          <Col className="my-md-5">
            <img
              src={london}
              className="shadow rounded"
              style={{
                width: "100%",
                margin: "auto",
                objectFit: "full",
              }}
              alt=""
            />
          </Col>
        </Row>
      </Container>

      <Container className="py-4 pb-1 my-4" fluid>
        <div className="text-center">
          <h1 className="mt-4 brand-font">Join Goodnight Zoom</h1>

          <Link to="/safety" className="mt-4">
            <Button variant="light" size="lg">
              Safety + Trust
            </Button>
          </Link>
          <br />
          <br />
          <Link to="/about" className="mt-4">
            <Button variant="light" size="lg">
              How it works
            </Button>
          </Link>
          <br />
          <br />
          <Link to="/register" className="mt-4">
            <Button variant="primary" size="lg" className="hover-arrow">
              Sign Up
            </Button>
          </Link>
        </div>
      </Container>
      {/* <Container fluid>
        <Row className="my-4 px-4">
          <Col sm={4}>
            {" "}
            <TwitterTweetEmbed tweetId={"1250077914084737025"} />
          </Col>
          <Col sm={4}>
            {" "}
            <TwitterTweetEmbed tweetId={"1245142737004965890"} />
          </Col>
          <Col sm={4}>
            <TwitterTweetEmbed tweetId={"1245139557802074112"} />
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  saveAnonUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { saveAnonUser })(Landing);
