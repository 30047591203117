import React from "react";
import Button from "react-bootstrap/Button";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

const RegisterButton = ({
  attending,
  rsvpStorytime,
  cancelStorytime,
  id,
  loading
}) => {
  return (
    <>
      {attending ? (
        <Button
          variant="light text-danger"
          size="lg"
          onClick={e => cancelStorytime(id)}
        >
          Cancel
        </Button>
      ) : (
        <>
          <Button
            variant="success"
            size="lg"
            onClick={e => {
              rsvpStorytime(id);

              toast.notify("Confirmed! Check your email for more details.");
            }}
            className="hover-shadow"
          >
            Register
          </Button>
        </>
      )}
    </>
  );
};

export default RegisterButton;
