import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import Form from "react-bootstrap/Form";

function City(props) {
  const { currentStep, handleSelect, stepVal } = props;

  const [address, setAddress] = useState("");

  const citySelect = async value => {
    setAddress(value);
    handleSelect(value);
  };

  return currentStep !== stepVal ? null : (
    <>
      <h2>
        {" "}
        <span role="img" aria-label="city">
          {" "}
          📍{" "}
        </span>{" "}
        What city do you live in?
      </h2>
      <p>We try and pair people in the timezone.</p>

      <Form.Group controlId="city" className="mb-4">
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={e => citySelect(e)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div>
              <input
                {...getInputProps({ placeholder: "What city do you live in?" })}
                className="form-control form-control-lg"
                size="lg"
              />

              <div>
                {loading ? <div>loading...</div> : null}
                {console.log(suggestions)}
                {suggestions.map(suggestion => {
                  const style = {
                    fontSize: "18px",
                    padding: "5px",
                    backgroundColor: suggestion.active ? "pink" : "#fff"
                  };
                  return (
                    <div
                      key={suggestion.id}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Group>
    </>
  );
}

export default City;
