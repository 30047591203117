import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function AnythingElse(props) {
  const { currentStep, value, onChange, stepVal, back } = props;
  return currentStep !== stepVal ? null : (
    <>
      <h2>
        <span role="img" aria-label="anything_else">
          {" "}
          🙋{" "}
        </span>{" "}
        Is there anything else you'd like us to know?
      </h2>

      <p>
        For example, is there a particular time of day you want to do storytime?
        Let us know if there's anything you're unsure about.
      </p>
      <Form.Group controlId="FavoriteBook">
        <Form.Control
          name="anything_else"
          value={value}
          as="textarea"
          size="lg"
          maxLength="300"
          placeholder="Looking forward to storytime!"
          onChange={onChange}
        />
      </Form.Group>

      <div>
        <Row className="mt-4">
          <Col xs={4}>
            <Button variant="light" size="lg" block onClick={back}>
              Back
            </Button>
          </Col>
          <Col xs={8}>
            <Button type="submit" variant="success" size="lg" block>
              Complete profile
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AnythingElse;
