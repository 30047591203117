export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_STORYTIME = "GET_STORYTIME";
export const GET_STORYTIME_ID = "GET_STORYTIME_ID";
export const STORYTIME_ERROR = "STORYTIME_ERROR";
export const RSVP_STORYTIME = "RSVP_STORYTIME";
export const CANCEL_STORYTIME = "CANCEL_STORYTIME";
export const APPLY = "APPLY";
export const ANON_USER = "ANON_USER";
export const INVITE = "INVITE";
export const TOKEN_OK = "TOKEN_OK";
export const DELETE_STORYTIME = "DELETE_STORYTIME";
export const NEW_STORYTIME = "NEW_STORYTIME";
