import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const FAQs = () => {
  return (
    <Container className="p-5">
      <h1>FAQs</h1>
      <hr />
      <h5>
        I'm worried about online safety. Why should I trust Goodnight Zoom?
      </h5>
      <p>
        Online safety is our number one priority. Check out our{" "}
        <Link to="/safety">Safety + Trust</Link> page for more details. Overall,
        we know that remote storytime is an unusual idea — but we encourage you
        to try it. Almost every single one of our users has had an
        overwhelmingly positive and warm experience. If you're still unsure,
        reach out to us and we can connect you with someone who's used the
        platform — we're confident they'll have only good things to say.
      </p>

      <br />
      <h5>How do we make money?</h5>
      <p>
        Right now, our goal is just to connect as many seniors as possible — so
        we don't. Down the road, we may make money from publishers who may want
        to feature books, or insurance companies paying us because we improve
        the wellbeing of the seniors using our system.
      </p>
      <br />
      <h5>I don't want my storytime recorded.</h5>
      <p>
        We assume by default that your storytime session is private, and nothing
        will be recorded or saved.
      </p>
      <br />
      <h5>
        I'm a senior, but I don't want to be read to. I only want to read
        stories.
      </h5>
      <p>
        No problem! After you sign up you'll receive more info about your
        reading preferences. You can choose whether you only want to be paired
        with children, adults, or if you don't mind.{" "}
      </p>
      <br />
      <h5>When is my remote storytime?</h5>
      <p>
        After you sign up, we'll send an email that will help get your
        scheduling preferences setup.
      </p>
      <br />
      <h5>How old do I have to be for this?</h5>
      <p>Any age is welcome!</p>
      <br />
      <h5>What if I want to read but don't have a story?</h5>
      <p>
        <Link to="/books">
          Check out this free selection of open-source stories.
        </Link>
      </p>

      <br />
      <h5>Is this free?</h5>
      <p>Yes.</p>
      <br />

      <Link to="/signup">
        <Button variant="cta" block className="text-white" size="lg">
          Sign Up
        </Button>
      </Link>
    </Container>
  );
};

export default FAQs;
