import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";
import { Link } from "react-router-dom";
import explainer from "../../assets/explainers/upcoming.png";

const Listening = () => {
  return (
    <Container className="p-5">
      <h1>How does this work?</h1>
      <hr />
      <p className="lead">
        Each week, we host 15-20 min interactive storytimes with a trusted
        senior. These storytimes are via videochat, just like a Zoom or Facetime
        call.
      </p>

      <h5 className="text-center">EXAMPLE!</h5>
      <p className="lead">
        Once you create an account, you'll be directed to a page that shows you
        all the upcoming storytimes for that week.
      </p>

      <div className="text-center mt-1">
        <Figure>
          <Figure.Image width={350} alt="171x180" src={explainer} />
          <Figure.Caption>An example storytime.</Figure.Caption>
        </Figure>
      </div>
      <p className="lead">
        For each storytime, you'll see the time of day (in your timezone), the
        recommended age group, and more info about the reader.
      </p>

      <p className="lead my-5">
        Click the <Button variant="success mx-1">Register</Button> button to
        sign up for the storytime. Feel free to sign up for as many as you'd
        like!
      </p>

      <p className="lead my-5">
        On the day of your storytime, we'll send an email confirmation and a
        link to the videochat!
      </p>

      <div className="text-center">
        <h1 className="mt-4 brand-font">Join Goodnight Zoom</h1>

        <Link to="/register" className="mt-4">
          <Button variant="primary" size="lg">
            Sign Up &rarr;
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Listening;
