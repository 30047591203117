import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createProfile } from "../../actions/profile";
import AgeRange from "../onboarding/AgeRange";
import AnythingElse from "../onboarding/AnythingElse";
import City from "../onboarding/City";
import Demo from "../onboarding/Demo";
import FavoriteBook from "../onboarding/FavoriteBook";
import Welcome from "../onboarding/Welcome";

const Onboarding = ({ createProfile, history }) => {
  const [formData, setFormData] = useState({
    demo: "I am a...",
    kid_age_range: [],
    senior_age_range: "I am...",
    adult_age_range: "I am...",
    city: "",
    favorite_book: "",
    book_access: false,
    anything_else: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  const {
    demo,
    kid_age_range,
    senior_age_range,
    adult_age_range,
    city,
    favorite_book,
    anything_else
  } = formData;

  // what step are we on?
  const [step, setStep] = useState(0);

  const onChange = e => {
    toggleError(false);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    toggleShow(false);
    createProfile(formData, history);
  };

  const citySelect = async value => {
    setFormData({ ...formData, city: value });
  };

  const [error, toggleError] = useState(false);

  const showError = e => {
    toggleError(true);
  };

  const prevStep = e => {
    setStep(step - 1);
    toggleError(false);
  };
  const nextStep = e => {
    setStep(step + 1);
    toggleError(false);
  };

  const [show, toggleShow] = useState(true);

  const onCheck = e => {
    const newAge = e.target.value;
    const checked = e.target.checked;

    if (!checked) {
      const newAgeRange = kid_age_range.filter(age => age !== newAge);
      setFormData({ ...formData, kid_age_range: newAgeRange });
    } else {
      const newAgeRange = [...kid_age_range, newAge];
      setFormData({ ...formData, kid_age_range: newAgeRange });
    }
  };

  const getBar = () => {
    if (step === 0) {
      return (
        <Button onClick={e => nextStep()} className="hover-arrow">
          Next Page
        </Button>
      );
    } else if (
      (demo === "I am a..." && step === 1) ||
      (demo === "adult" && adult_age_range === "I am..." && step === 2) ||
      (demo === "senior" && senior_age_range === "I am..." && step === 2) ||
      (city === "" && step === 3)
    ) {
      return (
        <>
          <Button variant="light" onClick={e => prevStep()}>
            Back
          </Button>
          <Button className="disabled hover-arrow" onClick={e => showError(e)}>
            Next Page
          </Button>
        </>
      );
    } else if (
      (demo === "parent" && step === 2 && kid_age_range.length === 0) ||
      (step === 4 && favorite_book === "")
    ) {
      return (
        <>
          <Button variant="light" onClick={e => prevStep()}>
            Back
          </Button>
          <Button
            variant="info"
            onClick={e => nextStep()}
            className="hover-arrow"
          >
            Skip
          </Button>
        </>
      );
    } else if (step === 5) {
      return null;
    } else {
      return (
        <>
          <Button variant="light" onClick={e => prevStep()}>
            Back
          </Button>
          <Button
            variant="primary"
            onClick={e => nextStep()}
            className="hover-arrow"
          >
            Next Page
          </Button>
        </>
      );
    }
  };

  return (
    <Fragment>
      <Spinner
        animation="border"
        role="status"
        style={{ margin: "auto" }}
        className={show ? "d-none" : "d-block"}
        variant="primary"
        size="lg"
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
      <Form
        onSubmit={e => onSubmit(e)}
        style={{ height: "30%" }}
        className={show ? "d-block" : "d-none"}
      >
        <Welcome stepVal={0} currentStep={step} />

        <Demo
          stepVal={1}
          currentStep={step}
          demo={demo}
          onChange={e => onChange(e)}
        />

        <AgeRange
          stepVal={2}
          currentStep={step}
          demo={demo}
          onChange={e => onChange(e)}
          onCheck={e => onCheck(e)}
          kid_age_range={kid_age_range}
          adult_age_range={adult_age_range}
          senior_age_range={senior_age_range}
        />

        <City
          stepVal={3}
          currentStep={step}
          handleSelect={e => citySelect(e)}
        />

        <FavoriteBook
          stepVal={4}
          currentStep={step}
          value={favorite_book}
          onChange={e => onChange(e)}
        />
        <AnythingElse
          stepVal={5}
          currentStep={step}
          value={anything_else}
          onChange={e => onChange(e)}
          back={e => setStep(step - 1)}
        />

        {error ? (
          <Alert variant="info">
            Please select an answer above.
            <br />
            <br />
            If you need any help, click the chat button in the lower right hand
            corner and we'll be happy to walk you through this.
          </Alert>
        ) : null}

        <div className="text-center text-md-right">
          <ProgressBar now={step * 20} className="mt-4" />

          <p className="text-muted mt-2">Step {step + 1} of 6</p>
          <ButtonGroup className="mt-3 w-100" size="lg">
            {getBar()}
          </ButtonGroup>
        </div>

        <div className="text-center my-4">
          If you need any help, click the chat button in the lower right hand
          corner.
        </div>
      </Form>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Fragment>
  );
};

Onboarding.propTypes = {
  createProfile: PropTypes.func.isRequired
};

export default connect(
  null,
  { createProfile }
)(withRouter(Onboarding));
