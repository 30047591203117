import React from "react";
import Form from "react-bootstrap/Form";

const AgeRange = ({ age_range, onChange, currentStep, stepVal }) => {
  return currentStep !== stepVal ? null : (
    <div>
      <h2>What age range do you want to read to?</h2>
      <br />
      <Form.Group controlId="age_range" className="mb-4">
        <Form.Control
          name="age_range"
          value={age_range}
          size="lg"
          placeholder="4-7 years old"
          onChange={onChange}
        />
      </Form.Group>
    </div>
  );
};

export default AgeRange;
