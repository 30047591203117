import moment from "moment-timezone";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { FaArrowLeft } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { Link, withRouter } from "react-router-dom";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import RegisterButton from "./RegisterButton";

export const StorytimeCard = ({
  history,
  storytime,
  profile,
  rsvpStorytime,
  cancelStorytime,
  deleteStorytime
}) => {
  const {
    _id,
    guests,
    host,
    name,
    bio,
    email,
    age_range,
    event_date,
    profile_photo
  } = storytime;

  const attending = () => {
    return guests.some(guest => guest.user === profile.user._id);
  };

  const isHost = () => {
    return host === profile.user._id;
  };

  const deleteStorytimeHandler = (storytime_id, history) => {
    if (window.confirm("Are you sure you want to delete this storytime?")) {
      deleteStorytime(storytime_id, history);
      toast.notify("Storytime Cancelled.");
    }
  };

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const cleanDate = moment.tz(event_date, browserTimezone).format("MMMM Do");
  const cleanDay = moment.tz(event_date, browserTimezone).format("dddd");
  const cleanTime = moment.tz(event_date, browserTimezone).format("h:mm a");

  return (
    <>
      <Card className="m-3" key={_id}>
        {isHost() ? (
          <Card.Header className="d-flex justify-content-between">
            <div>You're the host!</div>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={e => {
                deleteStorytimeHandler(_id, history);
              }}
              className="mt-1"
            >
              Cancel this storytime
            </Button>
          </Card.Header>
        ) : attending() ? (
          <Card.Header className="bg-success text-center">
            <h5 className="text-white py-0 my-0">Registered</h5>
          </Card.Header>
        ) : null}

        <Card.Body>
          <Link to="/home">
            <Button variant="light">
              <FaArrowLeft className="mr-2" />
              Back
            </Button>
          </Link>
          <Row>
            <Col sm={4} className="my-4">
              <Image
                src={profile_photo}
                alt="?"
                style={{
                  maxHeight: "15rem",
                  objectFit: "contain",
                  borderRadius: "2rem"
                }}
              />
            </Col>

            <Col xs={12} sm={8} className="mt-3">
              <h4 className="my-1">
                {cleanDay}, {cleanDate} {cleanTime}
              </h4>

              <h1 className="mb-1">
                {" "}
                <span>Storytime with</span>
                <span className="text-primary"> {name}</span>
              </h1>
              <p className="mb-2">
                {/* <VerifiedBadge /> */}

                <Badge className="bg-light mt-1 ml-2">Verified Reader</Badge>
                <Badge className="badge-primary-soft mt-1 ml-2">
                  for ages {age_range}
                </Badge>
              </p>

              <Card.Text className="mt-3">{bio}</Card.Text>
              <hr />
              <div className="my-4">
                <a href={`mailto:${email}?subject=Storytime with you`}>
                  <Button size="lg" variant="outline-primary" className="mr-2">
                    <FiMail className="mr-2 text-primary" />
                    Message
                  </Button>
                </a>
                <RegisterButton
                  id={_id}
                  cancelStorytime={cancelStorytime}
                  rsvpStorytime={rsvpStorytime}
                  attending={attending()}
                />

                <br />
                <br />
                {attending() ? (
                  <small className="text-muted">
                    No problem if you need to cancel. We'll let the host know.
                  </small>
                ) : (
                  <small className="text-muted">
                    After you click "Register," we'll send you an event
                    invitation to your email.
                  </small>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default withRouter(StorytimeCard);
