import React from "react";
import Form from "react-bootstrap/Form";

function Demo(props) {
  const { currentStep, demo, onChange, stepVal } = props;
  return currentStep !== stepVal ? null : (
    <>
      <h2>
        <span role="img" aria-label="profile">
          {" "}
          👤{" "}
        </span>{" "}
        Which best describes you?
      </h2>
      <Form.Group controlId="bio" className="mb-4 mt-4">
        <Form.Control
          as="select"
          size="lg"
          name="demo"
          value={demo}
          onChange={onChange}
          style={{ maxWidth: "30rem" }}
        >
          <option value="I am a..." disabled>
            I am a...
          </option>
          <option value="senior">Senior (65ish and older)</option>
          <option value="parent">
            Parent (and have kids eager for storytime)
          </option>
          <option value="adult">Adult</option>
        </Form.Control>
      </Form.Group>
    </>
  );
}

export default Demo;
