import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "react-bootstrap/Alert";

const Alerter = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <Alert
      key={alert.id}
      variant={alert.alertType}
      style={{
        maxWidth: "300px",
        margin: "auto"
      }}
      className="my-3"
    >
      {alert.msg}
    </Alert>
  ));

Alerter.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});
export default connect(mapStateToProps)(Alerter);
