import React from "react";
import moment from "moment-timezone";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Schedule = ({ event_date, handleDateChange, currentStep, stepVal }) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const cleanZone = moment.tz.zone(browserTimezone).abbr("");
  const cleanTime = moment.tz(event_date, browserTimezone).format("h:mm a");
  const cleanDate = moment
    .tz(event_date, browserTimezone)
    .format("dddd, MMMM Do");
  return currentStep !== stepVal ? null : (
    <div>
      <h2>What time works for you?</h2>
      <Form.Group controlId="start_time" className="mb-4">
        <Form.Label>
          Please select a date and time below. The default length is 15-20
          minutes, but we're flexible on timing!
        </Form.Label>{" "}
        <br />
        <DatePicker
          selected={event_date}
          onChange={handleDateChange}
          showTimeSelect
          className="mt-2 p-1"
          style={{
            borderRadius: ".5rem",
            borderColor: "lightgray"
          }}
          dateFormat="Pp"
        />
        <br />
        <p className="text-muted mt-1">
          {cleanDate} at {cleanTime} {cleanZone}
        </p>
      </Form.Group>
    </div>
  );
};

export default Schedule;
