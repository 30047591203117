import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaUserEdit } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../../actions/auth";
import { createProfile, getCurrentProfile } from "../../actions/profile";

const EditProfile = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
  logout
}) => {
  const [formData, setFormData] = useState({
    favorite_book: "",
    demo: "",
    city: "",
    anything_else: "",
    timezone: "",
    bio: ""
  });

  useEffect(() => {
    getCurrentProfile();
    setFormData({
      timezone: loading || !profile.timezone ? "" : profile.timezone,
      favorite_book:
        loading || !profile.favorite_book ? "" : profile.favorite_book,
      demo: loading || !profile.demo ? "" : profile.demo,
      anything_else:
        loading || !profile.anything_else ? "" : profile.anything_else,
      bio: loading || !profile.bio ? "" : profile.bio
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getCurrentProfile]);

  const { timezone, favorite_book, demo, bio } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const onSubmit = e => {
    e.preventDefault();
    createProfile(formData, history, true);
  };

  return (
    <Fragment>
      <Card style={{ maxWidth: "50rem", margin: "auto" }} className="mt-4">
        <Card.Body className="bg-primary-alt">
          <Form onSubmit={e => onSubmit(e)}>
            <div className="d-flex justify-content-between">
              <h1>
                <FaUserEdit /> Edit Profile
              </h1>
              <Button variant="light" onClick={logout}>
                Logout
              </Button>
            </div>
            <hr />

            <Form.Group controlId="bio">
              <Form.Label>Your bio</Form.Label>

              <Form.Control
                name="bio"
                value={bio}
                as="textarea"
                size="lg"
                maxLength="300"
                placeholder="About you"
                onChange={onChange}
              />
            </Form.Group>

            <Form.Group controlId="bio" className="mb-4 mt-4">
              <Form.Label>Which best describes you?</Form.Label>
              <Form.Control
                as="select"
                size="lg"
                name="demo"
                value={demo}
                onChange={onChange}
                style={{ maxWidth: "30rem" }}
              >
                <option value="I am a..." disabled>
                  I am a...
                </option>
                <option value="senior">Senior (65ish and older)</option>
                <option value="parent">
                  Parent (and have kids eager for storytime)
                </option>
                <option value="adult">
                  Adult (and I want to read to seniors)
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="FavoriteBook">
              <Form.Label>
                What's one of your favorite children's books?
              </Form.Label>
              <Form.Control
                name="favorite_book"
                value={favorite_book}
                size="lg"
                maxLength="300"
                placeholder="James and the Giant Peach"
                onChange={onChange}
              />
            </Form.Group>

            <br />
            <br />
            <Form.Group>
              <Form.Label>
                Your timezone: <span className="text-black">{timezone}</span>
              </Form.Label>
              <br />
              <a href="mailto:info@goodnightzoom.com?subject=Change city">
                Change timezone
              </a>
            </Form.Group>
            <br />

            <div className="mt-3">
              <Row>
                <Col>
                  <Link to="/home">
                    <Button variant="light" size="lg" block>
                      Cancel
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Button variant="success ml-2" type="submit" size="lg" block>
                    Save and Close
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

EditProfile.propTypes = {
  logout: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { createProfile, getCurrentProfile, logout }
)(withRouter(EditProfile));
