import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import DashboardActions from "./DashboardActions";
import Onboarding from "./Onboarding";
import { getStorytime } from "../../actions/storytime";

const Dashboard = ({
  getCurrentProfile,
  getStorytime,
  profile: { profile, loading },
  storytime: { storytime, storytime_loading }
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);
  useEffect(() => {
    getStorytime();
  }, [getStorytime]);

  return loading && storytime_loading && profile == null ? (
    <div className="text-center mt-5">
      <Spinner animation="border" role="status">
        <span className="sr-only text-center">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Fragment>
      <Container className="mt-4">
        {profile !== null && storytime !== null ? (
          <Fragment>
            <DashboardActions storytime={storytime} profile={profile} />
          </Fragment>
        ) : (
          <Fragment>
            <Container style={{ maxWidth: "50rem" }}>
              <Onboarding />
            </Container>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getStorytime: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  storytime: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  storytime: state.storytime
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, getStorytime }
)(Dashboard);
