import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { FaBookReader } from "react-icons/fa";
import { apply } from "../../actions/storytime";

const Apply = ({ history, apply }) => {
  const onSubmit = e => {
    e.preventDefault();
    apply(history);
  };

  return (
    <>
      <Card style={{ maxWidth: "50rem", margin: "auto" }} className="mt-4">
        <Card.Body className="bg-primary-alt">
          <Form onSubmit={e => onSubmit(e)}>
            <div>
              <h1>
                <FaBookReader /> Host your own storytime
              </h1>
            </div>
            <hr />
            <br />
            <p>
              Woohoo! Looking forward to you reading on Goodnight Zoom. After
              you click "Confirm", I'll reach out within a day or so to setup a
              quick call to get to know you!
            </p>
            <div className="mt-3">
              <Row>
                <Col>
                  <Link to="/home">
                    <Button variant="light" size="lg" block>
                      Cancel
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Button variant="success ml-2" type="submit" size="lg" block>
                    Confirm
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
export default connect(
  null,
  { apply }
)(withRouter(Apply));
