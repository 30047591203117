import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import mousePDF from "../../assets/book_files/a_house_for_a_mouse.pdf";
import stayPDF from "../../assets/book_files/come_stay_with_me.pdf";
import duduPDF from "../../assets/book_files/dudus_hat.pdf";
import dudu from "../../assets/book_images/dudu.png";
import mouse from "../../assets/book_images/mouse.png";
import stay from "../../assets/book_images/stay.png";

const Books = () => {
  return (
    <Container className="py-5">
      <h2>Don't have any books to read?</h2>
      <p className="lead">
        No problem. Thanks to the Gutenberg Project and Bookdash, here's a
        collection of great public-domain stories you can read, for free:
      </p>

      <Row className="justify-content-center">
        <Col className="my-3">
          <Card className="mx-auto" style={{ width: "20rem" }}>
            <Card.Img
              variant="top"
              className="d-none d-sm-block"
              style={{ height: "12rem", objectFit: "cover" }}
              src="https://upload.wikimedia.org/wikipedia/en/1/1d/Thejunglebook_movieposter.jpg"
            />
            <Card.Body>
              <Card.Title>The Jungle Book</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Rudyard Kipling
              </Card.Subtitle>

              <Card.Text>
                Raised by a family of wolves since birth, Mowgli must leave the
                only home he's ever known when the fearsome tiger Shere Khan
                unleashes his mighty roar.
              </Card.Text>
              <Link
                to="jungle"
                className="btn btn-primary text-center btn-block"
              >
                The Jungle Book
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-3">
          <Card style={{ width: "20rem" }} className="mx-auto">
            <Card.Img
              variant="top"
              style={{ height: "12rem", objectFit: "cover" }}
              src="https://upload.wikimedia.org/wikipedia/commons/3/33/Duckling_03.jpg"
              alt="Duckling 03.jpg"
              className="d-none d-sm-block"
            />
            <Card.Body>
              <Card.Title>Fairy Tales of Hans Christian Anderson</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Hans Christian Anderson
              </Card.Subtitle>
              <Card.Text>
                Hans Christian Andersen stories include "The Ugly Duckling" and
                "The Princess and the Pea," classics of the genre.
              </Card.Text>
              <Link
                to="fairy"
                className="btn btn-primary text-center btn-block"
              >
                Read Fairy Tales
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-3">
          <Card style={{ width: "20rem" }} className="mx-auto">
            <Card.Img
              variant="top"
              style={{ height: "12rem", objectFit: "cover" }}
              className="d-none d-sm-block"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/De_Alice%27s_Abenteuer_im_Wunderland_Carroll_pic_23_edited_1_of_2.png/1200px-De_Alice%27s_Abenteuer_im_Wunderland_Carroll_pic_23_edited_1_of_2.png"
              alt="De Alice's Abenteuer im Wunderland Carroll pic 23 edited 1 of 2.png"
            />
            <Card.Body>
              <Card.Title>Alice's Adventures in Wonderland</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Lewis Carroll
              </Card.Subtitle>
              <Card.Text>
                Alice is a young girl who falls asleep in a meadow and dreams
                that she follows the White Rabbit down a rabbit hole...
              </Card.Text>

              <Link
                to="alice"
                className="btn btn-primary text-center btn-block"
              >
                Read Alice in Wonderland
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-3">
          <Card style={{ width: "20rem" }} className="mx-auto">
            <Card.Img
              variant="top"
              style={{ height: "12rem", objectFit: "cover" }}
              className="d-none d-sm-block"
              src={mouse}
            />
            <Card.Body>
              <Card.Title>A House for a Mouse</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Michele Fry, Amy Uzzell & Jennifer Jacobs
              </Card.Subtitle>
              <Card.Text>
                A simple and cute story for very young children, featuring a
                mouse, a puppy, and a parrot, and beautiful illustrations.
              </Card.Text>
              <a
                href={mousePDF}
                className="btn btn-primary text-center btn-block"
              >
                Read A House for a Mouse
              </a>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-3">
          <Card style={{ width: "20rem" }} className="mx-auto">
            <Card.Img
              variant="top"
              style={{ height: "12rem", objectFit: "cover" }}
              className="d-none d-sm-block"
              src={stay}
            />
            <Card.Body>
              <Card.Title>Come Stay With Me</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Nasrin Siege, Subi Basa, Job Mubinya
              </Card.Subtitle>
              <Card.Text>
                Tendai Turtle’s Granny has hurt her shell and Mum and Dad have
                to go to help her. “Come stay with me,” says Tendai’s best
                friend Busi Bunny, and the fun begins.
              </Card.Text>
              <a
                href={stayPDF}
                className="btn btn-primary text-center btn-block"
              >
                Read Come Stay With Me
              </a>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-3">
          <Card style={{ width: "20rem" }} className="mx-auto">
            <Card.Img
              variant="top"
              style={{ height: "12rem", objectFit: "cover" }}
              className="d-none d-sm-block"
              src={dudu}
            />
            <Card.Body>
              <Card.Title>Dudu's Hat</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Stephen Wallace, Sarah McGregor & Benjamin Tetteh
              </Card.Subtitle>
              <Card.Text>
                Hats off! Dudu and Dumisani have made up a new game. Ssh! Don’t
                tell Teacher.
              </Card.Text>
              <a
                href={duduPDF}
                className="btn btn-primary text-center btn-block"
              >
                Read Dudu's Hat
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <p className="text-center lead">
        For more options: <br />
        <a href="https://bookdash.org/books/">Bookdash</a>
        <br />
        <a href="https://www.nypl.org/books-music-movies/ebookcentral/simplye">
          NY Public Library Online
        </a>
        <br />
      </p>
    </Container>
  );
};

export default Books;
