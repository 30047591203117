import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { login } from "../../actions/auth";

const Login = ({ setAlert, login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <Container fluid className="bg-cta-light pb-5">
      <Row>
        <Container className="mt-5">
          <Card
            style={{ maxWidth: "25rem", margin: "auto" }}
            className="shadow"
          >
            <Card.Body>
              <Form onSubmit={onSubmit}>
                <Card.Title className="mb-3">Log In</Card.Title>
                <Card.Subtitle className="mb-4 text-muted">
                  Let's get reading!
                </Card.Subtitle>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={e => onChange(e)}
                    autoComplete="email"
                    size="lg"
                    required
                  />
                </Form.Group>
                <Form.Control
                  name="password"
                  type="password"
                  value={password}
                  onChange={e => onChange(e)}
                  placeholder="Password"
                  size="lg"
                  autoComplete="current-password"
                  required
                />
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  size="lg"
                  block
                >
                  Enter
                </Button>

                <div className="text-center my-3">
                  <a href="mailto:info@goodnightzoom?subject=Password reset">
                    Forgot Password?
                  </a>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <Card style={{ maxWidth: "25rem", margin: "auto" }} className="mt-3">
            <Card.Body>
              <p className="text-muted text-center mb-0">
                Don't have an account?{" "}
                <Link to="/register" className="text-link">
                  Sign Up
                </Link>
              </p>
            </Card.Body>
          </Card>
        </Container>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { setAlert, login }
)(Login);
