import React from "react";
import Form from "react-bootstrap/Form";

function FavoriteBook(props) {
  const { currentStep, value, onChange, stepVal } = props;
  return currentStep !== stepVal ? null : (
    <>
      <h2>
        <span role="img" aria-label="book">
          {" "}
          📚{" "}
        </span>{" "}
        What's one of your favorite children's stories?
      </h2>
      <p>Or if you don't have one, your favorite genre.</p>
      <Form.Group controlId="FavoriteBook">
        <Form.Control
          name="favorite_book"
          value={value}
          size="lg"
          maxLength="300"
          placeholder="James and the Giant Peach"
          onChange={onChange}
        />
      </Form.Group>
    </>
  );
}

export default FavoriteBook;
