import React, { Component } from "react";
import { ReactReader } from "react-reader";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import FairyPDF from "../../assets/book_files/fairy.epub";
class Fairy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="text-center py-2">
          <Link
            to="/books"
            className=" btn btn-cta brand-font btn-lg text-white"
          >
            <IoMdArrowBack /> Back to Books
          </Link>
        </div>
        <div
          style={{
            position: "relative",
            height: "80vh",
            width: "100%"
          }}
        >
          {" "}
          <ReactReader
            url={FairyPDF}
            title="Fairy Tales"
            location={"epubcfi(/6/2[cover]!/6)"}
            locationChanged={epubcifi => console.log(epubcifi)}
            swipeable={true}
          />
        </div>
      </>
    );
  }
}

export default Fairy;
