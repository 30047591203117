import React from "react";

function Welcome(props) {
  const { currentStep } = props;
  return currentStep !== 0 ? null : (
    <>
      <h2>Host a storytime</h2>

      <p className="lead">
        The next couple of pages will help us get you set up to host a
        storytime, and will take about 2min.
      </p>
    </>
  );
}

export default Welcome;
