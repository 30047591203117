import React, { Fragment, useEffect, useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getCurrentProfile } from "../../actions/profile";
import { FaBookReader } from "react-icons/fa";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { newStorytime } from "../../actions/storytime";

import profileDefault from "../../assets/readers/profile_default.png";
import storyDefault from "../../assets/story_images/story_default.png";

const NewStorytime = ({
  profile: { profile, loading },
  getCurrentProfile,
  newStorytime,
  history
}) => {
  const roundMinutes = date => {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

    return date.setDate(date.getDate() + 1);
  };

  const [formData, setFormData] = useState({
    description: "",
    age_range: "",
    event_date: "",
    browser_timezone: ""
  });

  useEffect(() => {
    getCurrentProfile();
    setFormData({
      description: loading || !profile.bio ? "" : profile.bio,
      event_date: roundMinutes(new Date()),
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getCurrentProfile]);

  const { description, age_range, event_date, browser_timezone } = formData;

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const cleanZone = moment.tz.zone(browserTimezone).abbr("");

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const handleChange = date => {
    console.log(date);
    setFormData({
      ...formData,
      event_date: date
    });
  };

  const [file, setFile] = useState("");
  const [storyFile, setStoryFile] = useState("");

  const onImageChange = e => {
    setFile(e.target.files[0]);
    handleProfileImageUpload(e);
  };

  const onStoryImageChange = e => {
    setStoryFile(e.target.files[0]);
    handleStoryImageUpload(e);
  };

  const onSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", file);
    formData.append("image", storyFile);
    formData.append("description", description);
    formData.append("age_range", age_range);
    formData.append("event_date", moment.tz(event_date, "UTC").format());
    formData.append("browser_timezone", browser_timezone);
    newStorytime(formData, history);
  };

  const uploadedProfileImage = useRef(null);
  const uploadedStoryImage = useRef(null);

  const [profileUploaded, setProfile] = useState(false);
  const [storyUploaded, setStory] = useState(false);

  const handleProfileImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedProfileImage;
      current.file = file;

      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setProfile(true);
    }
  };

  const handleStoryImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedStoryImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setStory(true);
    }
  };

  return (
    <Fragment>
      <Card style={{ maxWidth: "50rem", margin: "auto" }} className="mt-4 mb-3">
        <Card.Body className="bg-primary-alt">
          <Form onSubmit={e => onSubmit(e)} enctype="multipart/form-data">
            <div className="d-flex justify-content-between">
              <h1>
                <FaBookReader /> Host a Storytime
              </h1>
            </div>
            <hr />

            {profile && profile.profile_photo ? null : (
              <Form.Group controlId="profile-image" className="mb-4">
                <br />
                <Form.Label>
                  {profileUploaded ? (
                    <span
                      role="img"
                      aria-label="profile-complete"
                      className="pr-2"
                    >
                      ✅
                    </span>
                  ) : null}
                  First, it looks like you don't have a profile image of you!
                  Upload one using the button below.
                </Form.Label>{" "}
                <input
                  type="file"
                  class="form-control-file"
                  accept="image/*"
                  onChange={onImageChange}
                  id="image-upload"
                  className="mx-auto"
                  placeholder="test"
                ></input>
                <div className="text-center">
                  <Image
                    src={profileDefault}
                    ref={uploadedProfileImage}
                    className="my-3"
                    roundedCircle
                    style={{
                      height: "10rem",
                      width: "10rem"
                    }}
                  />
                </div>
              </Form.Group>
            )}
            <Form.Group controlId="story-image" className="mb-4">
              <Form.Label>
                {storyUploaded ? (
                  <span
                    role="img"
                    aria-label="storyupload-complete"
                    className="pr-2"
                  >
                    ✅
                  </span>
                ) : null}
                Please upload a photo that depicts the kind of stories you will
                tell. This can be a photo of book cover, an illustration, etc --
                it's up to you!
              </Form.Label>{" "}
              <input
                type="file"
                class="form-control-file"
                accept="image/*"
                onChange={onStoryImageChange}
                id="image-upload"
                className="mx-auto"
                placeholder="test"
              ></input>
              <div className="text">
                <Image
                  src={storyDefault}
                  ref={uploadedStoryImage}
                  className="my-3"
                  rounded
                  style={{
                    height: "10rem",
                    width: "100%",
                    objectFit: "cover"
                  }}
                />
              </div>{" "}
            </Form.Group>

            <Form.Group controlId="start_time" className="mb-4">
              <Form.Label>
                What is the start time of your storytime? (all storytimes are
                30min)
              </Form.Label>{" "}
              <DatePicker
                selected={event_date}
                onChange={handleChange}
                showTimeSelect
                dateFormat="Pp"
              />
              <p className="text-muted py-2">
                Timezone: <b>{cleanZone}</b>
              </p>
            </Form.Group>

            <Form.Group controlId="age_range" className="mb-4">
              <Form.Label>What age range would you like to read to?</Form.Label>
              <Form.Control
                name="age_range"
                value={age_range}
                size="lg"
                placeholder="4-7 years old"
                onChange={onChange}
              />
            </Form.Group>

            <Form.Group controlId="description" className="mb-4">
              <Form.Label>Storytime Description</Form.Label>
              <p className="text-muted">
                This defaults to the bio you have in your profile page, but each
                storytime can have its own description.
              </p>
              <Form.Control
                name="description"
                value={description}
                as="textarea"
                size="lg"
                maxLength="300"
                placeholder="About you"
                onChange={onChange}
              />
            </Form.Group>
            <div className="mt-3">
              <Row>
                <Col>
                  <Link to="/home">
                    <Button variant="light" size="lg" block>
                      Cancel
                    </Button>
                  </Link>
                </Col>
                <Col>
                  <Button variant="success ml-2" type="submit" size="lg" block>
                    Create
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, newStorytime }
)(withRouter(NewStorytime));
