import React from "react";
import Form from "react-bootstrap/Form";

import Image from "react-bootstrap/Image";
import profileDefault from "../../assets/readers/profile_default.png";

function ProfilePicture(props) {
  const {
    currentStep,
    profile,
    onImageChange,
    uploadedProfileImage,
    stepVal
  } = props;
  return currentStep !== stepVal ? null : (
    <>
      <h2>
        <span role="img" aria-label="anything_else">
          {" "}
          👤{" "}
        </span>{" "}
        A photo of you
      </h2>

      {profile.profile_photo ? (
        <p className="lead">
          Here is the current photo we have of you. If you want to change it,
          click the <b>Browse...</b> button below.
        </p>
      ) : (
        <p className="lead">
          First, we need a photo of you. Click 'browse' to upload one.
        </p>
      )}

      <Form.Group controlId="profile-image" className="mb-4 text-center">
        <Image
          src={profile.profile_photo ? profile.profile_photo : profileDefault}
          ref={uploadedProfileImage}
          className="my-3"
          roundedCircle
          style={{
            height: "10rem",
            width: "10rem"
          }}
        />
        <br />
        <input
          type="file"
          accept="image/*"
          onChange={e => onImageChange(e, "profile")}
          id="image-upload"
          className="btn btn-light mt-3"
        ></input>
      </Form.Group>
    </>
  );
}

export default ProfilePicture;
