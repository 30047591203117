import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import maha from "../../assets/about/maha.png";
import london from "../../assets/landing/london.png";
import cancel from "../../assets/about/cancel.png";
import books from "../../assets/about/books.png";

const About = () => {
  return (
    <>
      <Container className="p-5 text-center">
        <h1>How does it work?</h1>
        <p>
          Goodnight Zoom gives you access to curated storytellers from the
          around the world. Virtual storytime is a great break for busy parents,
          and a wonderful connecting opportunity for older adults.
        </p>
        <br />
        <Image
          style={{ maxHeight: "30rem", margin: "auto" }}
          src={london}
          className="rounded shadow"
        />

        <hr />
        <h5 className="text-center">How it works</h5>

        <p>
          {" "}
          Every week, we feature curated storytellers from around the world to
          read stories. Anyone, any age can sign up for one of these time-slots,
          and they're all free.
        </p>
        <div className="text-center mt-4">
          <Image
            style={{ maxHeight: "30rem", margin: "auto" }}
            src={maha}
            className="shadow rounded"
          />
        </div>
        <br />
        <p className="my-4">
          Sign up on your own schedule, and don't worry about cancelling last
          minute.
        </p>
        <br />
        <div className="text-center mt-4">
          <Image
            style={{ maxHeight: "20rem", margin: "auto" }}
            src={cancel}
            className="shadow rounded"
          />
        </div>
        <br />
        <p className="my-4">
          And, if you don't have access to books, we'll provide{" "}
          <Link to="/books">one</Link>.
        </p>
        <br />
        <div className="text-center my-4">
          <Image
            style={{ maxHeight: "20rem", margin: "auto" }}
            src={books}
            className="shadow rounded"
          />
        </div>
        <br />

        <h1 className="mt-4">Join Goodnight Zoom</h1>
        <Link to="/register" className="mt-4">
          <Button variant="primary" size="lg">
            Sign Up
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default About;
