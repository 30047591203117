import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const Description = ({
  description,
  onChange,
  currentStep,
  showError,
  prevStep,
  stepVal
}) => {
  return currentStep !== stepVal ? null : (
    <div>
      <h2>Finally, a little bit about yourself!</h2>
      <br />
      <Form.Group controlId="description" className="mb-4">
        <p className="text-muted">
          This is so each signup can get an idea of who you are and what kind of
          stories you'll tell before they sign up!
        </p>
        <Form.Control
          name="description"
          value={description}
          as="textarea"
          size="lg"
          maxLength="300"
          placeholder="I am..."
          onChange={onChange}
        />
      </Form.Group>

      <ButtonGroup className="mt-3 w-100" size="lg">
        <Button variant="light" onClick={e => prevStep()}>
          Back
        </Button>

        {description === "" ? (
          <Button
            variant="success"
            className="disabled hover-arrow "
            onClick={e => showError(e)}
          >
            Complete
          </Button>
        ) : (
          <Button
            variant="success"
            className="success hover-arrow"
            type="submit"
          >
            Complete
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export default Description;
