import {
  GET_STORYTIME,
  STORYTIME_ERROR,
  RSVP_STORYTIME,
  GET_STORYTIME_ID,
  DELETE_STORYTIME,
  NEW_STORYTIME,
  APPLY
} from "../actions/types";

const initialState = {
  storytime: null,
  single_storytime: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RSVP_STORYTIME:
    case NEW_STORYTIME:
    case GET_STORYTIME_ID:
      return {
        ...state,
        single_storytime: payload,
        loading: false
      };
    case GET_STORYTIME:
      return {
        ...state,
        storytime: payload,
        loading: false
      };
    case DELETE_STORYTIME:
      return {
        ...state,
        storytime: state.storytime.filter(story => story._id !== payload),
        loading: false
      };
    case STORYTIME_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case APPLY:
      return {
        ...state,
        status: payload
      };
    default:
      return state;
  }
}
