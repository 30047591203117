import React from "react";
import Calendar from "../storytimes/Calendar";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { FaUserEdit, FaBookReader, FaBookOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment-timezone";
import areYouHosting from "../../utils/storytimes";

export const DashboardActions = ({ storytime, profile }) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <div className="mt-4">
        <Container style={{ maxWidth: "55rem" }}>
          <div>
            <Row>
              <Col sm={6}>
                <h5>Welcome to Goodnight Zoom!</h5>
                <p>
                  Every week, we offer storytimes hosted by seniors. All
                  storytimes are 15-20min.
                  <br />
                  <br />
                </p>
              </Col>
            </Row>
          </div>

          <hr />
          <h5 className="mb-0">Storytimes this week </h5>
          <p>Click on a timeslot to learn more about the reader.</p>
          <Calendar storytimes={storytime} profile={profile} />

          {/* If there is a single storytime you are hosting */}
          {areYouHosting(storytime, profile) ? (
            <>
              <Card>
                <Card.Body>
                  <Card.Title>Storytimes you are hosting</Card.Title>

                  {/* For each one, write out the event date, video link, and guest list */}
                  {storytime.map(story =>
                    story.host === profile.user._id ? (
                      <div className="pt-4" key={profile.user._id}>
                        <p>
                          <b>Event Date:</b>{" "}
                          {moment
                            .tz(story.event_date, browserTimezone)
                            .format("dddd")}
                          ,
                          <span>
                            {" "}
                            {moment
                              .tz(story.event_date, browserTimezone)
                              .format("MMMM Do")}
                          </span>
                          {" at "}
                          {moment
                            .tz(story.event_date, browserTimezone)
                            .format("h:mm a")}{" "}
                          {moment.tz.zone(browserTimezone).abbr("")}
                        </p>{" "}
                        <p>
                          <b>Videochat Link:</b>{" "}
                          {story.video == null
                            ? "We'll email you the day of for the link."
                            : story.video}
                        </p>
                        <p>
                          <b>Guest List:</b>{" "}
                          {story.guests.length > 0 ? (
                            <ul>
                              {story.guests.map(guest => (
                                <li key={guest._id}>{guest.name}</li>
                              ))}
                            </ul>
                          ) : (
                            "No one attending yet -- invite your friends and family!"
                          )}
                        </p>
                      </div>
                    ) : null
                  )}
                </Card.Body>
              </Card>
            </>
          ) : null}
          <Card className="d-block d-md-none">
            <Card.Body className="text-center">
              <Row>
                <Col>
                  <Link
                    to={
                      profile === null || profile.approved_reader
                        ? "/hosting"
                        : "/apply"
                    }
                  >
                    <FaBookReader />
                    <br />
                    Host
                  </Link>
                </Col>
                <Col>
                  <Link to="/books">
                    <FaBookOpen />
                    <br />
                    Books
                  </Link>
                </Col>
                <Col>
                  <Link to="/edit-profile">
                    <FaUserEdit />
                    <br />
                    Profile
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default DashboardActions;
